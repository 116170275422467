import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "Inter Regular, sans-serif",
    fontWeight: 400,
    border: "1px solid",
});

const sizes = {
    sm: defineStyle({
        px: "0.622rem",
        py: "0.518rem",
        fontSize: "0.7262rem",
        lineHeight: "1.0895rem",
    }),
    md: defineStyle({
        px: 3,
        py: 2.5,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
    }),
    lg: defineStyle({
        px: "0.87rem",
        py: "0.73rem",
        fontSize: "1.02rem",
        lineHeight: "1.5356rem",
    }),
};

const primary = {
    bg: "primaryTextAreaBg",
    borderRadius: "0.25rem",
    borderColor: "primaryTextAreaBorder",
    color: "primaryTextAreaText",

    _placeholder: {
        color: "primaryTextAreaPlaceholder",
    },

    _hover: {
        borderColor: "primaryTextAreaHoverBorder",
    },

    _focusVisible: {
        borderColor: "primaryTextAreaFocusBorder",
        boxShadow: `0 0 0 1px var(--chakra-colors-primaryTextAreaFocusShadow)`,
    },

    _disabled: {
        opacity: 1,
        bg: "primaryTextAreaDisabledBg",
        borderColor: "primaryTextAreaDisabledBorder",
        color: "primaryTextAreaDisabledText",
        _hover: {
            opacity: 1,
            bg: "primaryTextAreaDisabledHoverBg",
            borderColor: "primaryTextAreaDisabledHoverBorder",
            color: "primaryTextAreaDisabledHoverText",
        },
    },
};

const variants = {
    primary,
};

export const Textarea = defineStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
