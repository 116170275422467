import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import logoSrc from "../assets/images/logo.svg";
import { useTrans } from "../hooks";
import { AppIcon } from "./AppIcon";
import { APPC, cPath } from "../config";
import { useAuthData } from "../contexts";

export const AppPublicHeader = () => {
    const { t } = useTrans();
    const { isAuthenticated } = useAuthData();

    return (
        <Flex
            h={"full"}
            gap={6}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <NavLink to={cPath("PUBLIC.HOME_PAGE")}>
                <Box w={"210px"}>
                    <Image
                        src={logoSrc}
                        alt={APPC.SITE_NAME}
                        loading="lazy"
                        w={"100%"}
                    />
                </Box>
            </NavLink>

            <NavLink
                to={
                    isAuthenticated
                        ? cPath("ADMIN.DASHBOARD_PAGE")
                        : cPath("PUBLIC.SECURITY.LOGIN_PAGE")
                }
            >
                <Flex
                    height={"44px"}
                    px={5}
                    borderRadius={"full"}
                    textStyle={"semiBoldSm"}
                    bg={"#FFFFFF"}
                    _hover={{ bg: "#dddddd" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <AppIcon
                        name={
                            isAuthenticated
                                ? "icl-developer-board"
                                : "icl-key-alt"
                        }
                    />
                    <Text ml={2} whiteSpace={"nowrap"}>
                        {isAuthenticated
                            ? t("fnav:dashboard")
                            : t("fnav:memberLogin")}
                    </Text>
                </Flex>
            </NavLink>
        </Flex>
    );
};
