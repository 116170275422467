import { Outlet } from "react-router-dom";
import { Container, Grid, GridItem } from "@chakra-ui/react";
import { AppPublicFooter, AppPublicHeader } from "../components";

const PublicLayout = () => {
    const templateColumns = "1fr";
    const templateRows = "8rem 1fr 6rem";
    const templateAreas = `"header" "mainContent" "footer"`;
    return (
        <>
            <Grid
                templateColumns={templateColumns}
                templateRows={templateRows}
                templateAreas={templateAreas}
                minH="100vh"
            >
                <GridItem
                    gridArea="header"
                    borderBottom={"1px solid"}
                    borderColor={"borderPrimary"}
                    bg={"#ebebeb"}
                >
                    <Container maxW="1230px" h={"full"} px={6}>
                        <AppPublicHeader />
                    </Container>
                </GridItem>

                <GridItem gridArea="mainContent" overflowX={"auto"}>
                    <Container maxW="1230px" p={6}>
                        <Outlet />
                    </Container>
                </GridItem>
                <GridItem
                    gridArea="footer"
                    borderTop={"1px solid"}
                    borderColor={"borderPrimary"}
                    bg={"#ebebeb"}
                >
                    <Container maxW="1230px" h={"full"} px={6} py={6}>
                        <AppPublicFooter />
                    </Container>
                </GridItem>
            </Grid>
        </>
    );
};

export default PublicLayout;
