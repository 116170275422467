import { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTrans } from "../../../hooks/useTrans";
import { setPasswordPageSchema } from "./schema";
import {
    Button,
    Input,
    VStack,
    Box,
    Image,
    useToast,
    Center,
} from "@chakra-ui/react";
import { User, UserApi } from "../../../apis";
import { AppFormControl, AppLoader } from "../../../components";
import { APPC, cPath } from "../../../config";
import logoSrc from "../../../assets/images/logo.svg";

export const SetPasswordPage = () => {
    // hooks
    const { t } = useTrans();
    const toast = useToast();
    const { code } = useParams();
    const navigate = useNavigate();
    console.log(code);

    // state
    const [loading, setLoading] = useState(false);

    // form
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        reset,
    } = useForm<User>({
        resolver: yupResolver(setPasswordPageSchema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = (formData: User) => {
        setLoading(true);
        formData.changePwdCode = code;
        UserApi.postUserSetPassword<User, User>(formData)
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("ppub.Security.SetPasswordPage:toast.success"),
                        status: "success",
                    });
                    reset();
                    navigate(cPath("PUBLIC.SECURITY.LOGIN_PAGE"));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <>
                    <NavLink to={cPath("PUBLIC.HOME_PAGE")}>
                        <Box
                            maxW={"28rem"}
                            py={{ base: 8, sm: 8 }}
                            mx={"auto"}
                            mt={{ base: 0, sm: 8 }}
                        >
                            <Center>
                                <Image
                                    w={"60%"}
                                    src={logoSrc}
                                    alt={APPC.SITE_NAME}
                                    loading="lazy"
                                />
                            </Center>
                        </Box>
                    </NavLink>
                    <Box
                        maxW={"28rem"}
                        py={{ base: 8, sm: 8 }}
                        px={{ base: 4, sm: 10 }}
                        borderRadius={"0.5rem"}
                        boxShadow={"0px 4px 36px 0px #96969629"}
                        mx={"auto"}
                        bg={"#fff"}
                    >
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <VStack gap={5} alignItems={"stretch"}>
                                <AppFormControl
                                    label={t("ent.User:plainPassword.label")}
                                    isInvalid={!!errors?.plainPassword}
                                    message={errors?.plainPassword?.message}
                                >
                                    <Input
                                        type="password"
                                        placeholder={t(
                                            "ent.User:plainPassword.placeholder"
                                        )}
                                        {...register("plainPassword")}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t(
                                        "ent.User:plainPasswordConfirm.label"
                                    )}
                                    isInvalid={!!errors?.plainPasswordConfirm}
                                    message={
                                        errors?.plainPasswordConfirm?.message
                                    }
                                >
                                    <Input
                                        type="password"
                                        placeholder={t(
                                            "ent.User:plainPasswordConfirm.placeholder"
                                        )}
                                        {...register("plainPasswordConfirm")}
                                    />
                                </AppFormControl>
                                <Button
                                    isDisabled={isSubmitting || loading}
                                    type="submit"
                                >
                                    {t(
                                        "ppub.Security.SetPasswordPage:button.setNewPassword"
                                    )}
                                </Button>
                            </VStack>
                        </form>
                    </Box>
                </>
            )}
        </>
    );
};
