import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class AppointmentApi extends EntityApi {
    protected static GET_COLLECTION = "/api/appointments";

    protected static GET_COLLECTION_MY_APPOINTMENT = "/api/my-appointment";

    protected static GET_ITEM = "/api/appointments/{id}";

    protected static PATCH_ITEM = "/api/appointments/{id}";

    public static async myAppointment<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.GET_COLLECTION_MY_APPOINTMENT,
        };
        return this.getCollection(page, extraParams, cancelToken);
    }
}
