import { Badge, Button, Flex, Text } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { AppointmentBooking } from "../apis";
import dayjs from "dayjs";

interface AppStatasticListCardProps {
    title?: string;
    data: AppointmentBooking[];
    onClick?: () => void;
}

export const AppStatasticListCard = ({
    title,
    data,
    onClick,
}: AppStatasticListCardProps) => {
    return (
        <Flex
            direction={"column"}
            p={"1.25rem"}
            gap={"1.5rem"}
            border={"1px solid #E4E4E4"}
            borderRadius={"4px"}
            bg={"white"}
            justifyContent={"space-between"}
        >
            {title && (
                <Text
                    textStyle={"mediumXl"}
                    fontWeight={500}
                    color={"#000000"}
                    whiteSpace={"nowrap"}
                >
                    {title}
                </Text>
            )}
            {data &&
                data?.map((item, index) => (
                    <Flex key={index} direction={"column"} gap={"0.5rem"}>
                        <Badge variant={"secondary"} lineHeight={"unset"}>
                            {dayjs(`${item?.startTime}`).format(
                                "HH:mm - MMM DD, YYYY"
                            )}
                        </Badge>
                        <Text
                            textStyle={"mediumSm"}
                            fontWeight={500}
                            color={"#000000"}
                        >
                            {item?.userFullName}
                        </Text>
                        <Text
                            textStyle={"regularXs"}
                            fontWeight={400}
                            color={"#727272"}
                        >
                            {item?.userEmail}
                        </Text>
                        <Text
                            textStyle={"regularXs"}
                            fontWeight={400}
                            color={"#727272"}
                        >
                            {item?.userPhone}
                        </Text>
                    </Flex>
                ))}
            {onClick && (
                <Button variant={"outline"} onClick={onClick}>
                    <AppIcon name="icl-chevron-right-alt" w="1rem" />
                </Button>
            )}
        </Flex>
    );
};
