import * as yup from "yup";

export const schema = (t: (val: string) => string, isScheduleDays: boolean) => {
    const validation = {
        // title: yup.string().required(t("cmn:val.required")),
        slotDuration: yup.number().required(t("cmn:val.required")),
        timezone: yup.string().required(t("cmn:val.required")),
        availability: yup.array().of(
            yup.object().shape({
                day: yup.string().required(t("cmn:val.required")),
                start: yup.string().required(t("cmn:val.required")),
                end: yup.string().required(t("cmn:val.required")),
            })
        ),
        // Note: appointmentReminderTime validation pending + check also other fields
    };
    if (isScheduleDays) {
        validation["scheduleDays"] = yup
            .string()
            .required(t("cmn:val.required"));
    } else {
        validation["startTime"] = yup.string().required(t("cmn:val.required"));
        validation["endTime"] = yup.string().required(t("cmn:val.required"));
    }
    return yup.object().shape({ ...validation });
};
