import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const appointmentBookingValidation = yup.object().shape({
        userFirstName: yup.string().required(t("cmn:val.required")),
        userLastName: yup.string().required(t("cmn:val.required")),
        userEmail: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.AppointmentBooking:userEmail.val.email")),
        userPhone: yup.string().required(t("cmn:val.required")),
    });

    return appointmentBookingValidation;
};
