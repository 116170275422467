import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";

export abstract class SpecialityApi extends EntityApi {
    protected static GET_COLLECTION = "/api/specialities";

    protected static POST_COLLECTION = "/api/specialities";

    protected static GET_ITEM = "/api/specialities/{id}";

    protected static PATCH_ITEM = "/api/specialities/{id}";

    protected static DELETE_ITEM = "/api/specialities/{id}";

    protected static GET_COLLECTION_GET_SPECIALITIES = "/get-specialities";

    public static async getSpecialities<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.GET_COLLECTION_GET_SPECIALITIES,
        };
        return this.getCollection(page, extraParams, cancelToken);
    }
}
