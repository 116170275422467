import { FC, useEffect, useState } from "react";
import {
    FormContainerStateReturnType,
    useAppUtil,
    useTrans,
} from "../../../hooks";
import {
    useToast,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerCloseButton,
    TableContainer,
    Tbody,
    Tr,
    Td,
    Table,
    Text,
    Badge,
} from "@chakra-ui/react";
import { AppointmentBooking, AppointmentBookingApi } from "../../../apis";
import { AppLoader } from "../../../components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { APPC } from "../../../config";

export const AppointmentBookingDetailView: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const { statusVariant } = useAppUtil();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AppointmentBooking>(
        new AppointmentBooking()
    );
    dayjs.extend(utc);

    useEffect(() => {
        setLoading(true);

        if (formContainerState.entityId) {
            AppointmentBookingApi.getItem<AppointmentBooking>(
                formContainerState.entityId
            )
                .then(({ errorMessage, isNotFound, response }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (isNotFound) {
                        toast({
                            title: t("cmn:message.entityNotFound"),
                            status: "error",
                        });
                    } else if (response !== null) {
                        setData(response);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [formContainerState.entityId]);

    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <Drawer
                    size={"lg"}
                    isOpen={formContainerState.isOpen}
                    placement="right"
                    onClose={() => formContainerState.close()}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            {t("padm.AppointmentBookingPage:text.booking")}
                        </DrawerHeader>
                        <DrawerBody>
                            <TableContainer>
                                <Table variant="simple">
                                    <Tbody>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:id.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>{data?.id}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:userFullName.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>{data?.userFullName}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:userEmail.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>{data?.userEmail}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:userPhone.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>{data?.userPhone}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:userSpeciality.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {data?.userSpeciality?.["name"]}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:status.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                <Badge
                                                    variant={statusVariant(
                                                        data.status
                                                    )}
                                                >
                                                    {data.status}
                                                </Badge>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:startTime.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {dayjs(data?.startTime)
                                                    .utc()
                                                    .format(
                                                        "DD-MM-YYYY HH:mm:ss"
                                                    )}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:endTime.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {dayjs(data?.endTime)
                                                    .utc()
                                                    .format(
                                                        "DD-MM-YYYY HH:mm:ss"
                                                    )}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:timezone.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                {data?.timezone && (
                                                    <Text whiteSpace="pre-wrap">
                                                        {
                                                            APPC.BE.Timezone[
                                                                data?.timezone
                                                            ]
                                                        }
                                                    </Text>
                                                )}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td pe={2}>
                                                {t(
                                                    "ent.AppointmentBooking:comment.label"
                                                )}
                                            </Td>
                                            <Td ps={2}>
                                                <Text whiteSpace="pre-wrap">
                                                    {data?.comment}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};
