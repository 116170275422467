import { Appointment, BaseEntity, Category } from ".";

export interface SlotModel {
    time: string;
    isFree: boolean;
}

export class User extends BaseEntity {
    public email: string = "";
    public roles: string[] = [];
    public userType: string = "";
    public plainPassword: string = "";
    public plainPasswordConfirm: string = "";
    public changePwdCode?: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public fullName: string = "";
    public fullNameNs: string = "";
    public salutation: string = "";
    public phone: string = "";
    public bioData: string = "";
    public timezone?: string = "";
    public appointments?: Appointment[];
    public category?: string | Category | null = null;
    public avatar?: string;
    public slots?: SlotModel[] | null = null;

    constructor(model: Partial<User> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PUser = Partial<User>;
