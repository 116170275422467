import { Button, HStack, Select, Text, useMediaQuery } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

interface AppPaginationProps {
    onClick: (pageNumber: number) => void;
    totalPages: number;
    activePage?: number;
}

export const AppPagination = ({
    onClick,
    totalPages,
    activePage = 1,
}: AppPaginationProps) => {
    const [isSmallerThan680] = useMediaQuery("(max-width: 680px)");

    let start = activePage - 4;
    if (start < 1) {
        start = 1;
    }

    let end = activePage + 4;
    if (end > totalPages) {
        end = totalPages;
    }

    if (isSmallerThan680) {
        start = 1;
        end = totalPages;
    }

    const pages: number[] = [];
    for (let i = start; i <= end; i++) {
        pages.push(i);
    }

    const onClickHandler = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        onClick(pageNumber);
    };

    return (
        <>
            <HStack
                border={"1px solid #E1E1E1"}
                borderRadius={"10px"}
                h={"90px"}
                alignItems="center"
                justifyContent="space-between"
                px={"2rem"}
            >
                <Button
                    w={"40px"}
                    h={"40px"}
                    variant={"outline"}
                    onClick={() => onClickHandler(activePage - 1)}
                >
                    <Text>
                        <AppIcon name="icl-arrow-left" w="1rem" />
                    </Text>
                </Button>
                {isSmallerThan680 ? (
                    <Select
                        size={"md"}
                        w={"4.375rem"}
                        value={activePage}
                        onChange={(event) => {
                            onClickHandler(Number(event.currentTarget.value));
                        }}
                    >
                        {pages?.map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </Select>
                ) : (
                    <HStack gap={3}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                w={"40px"}
                                h={"40px"}
                                variant={
                                    activePage === page ? "primary" : "outline"
                                }
                                onClick={() => onClickHandler(page)}
                            >
                                {page}
                            </Button>
                        ))}
                    </HStack>
                )}
                <Button
                    w={"40px"}
                    h={"40px"}
                    variant={"outline"}
                    onClick={() => onClickHandler(activePage + 1)}
                >
                    <Text>
                        <AppIcon name="icl-arrow-right" w="1rem" />
                    </Text>
                </Button>
            </HStack>
        </>
    );
};
