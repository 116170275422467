import {
    default as Axios,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource,
} from "axios";
import { axiosInstance } from "./AxiosInstance";
import {
    RequestParamsType,
    RequestPayloadDataType,
    SimpleObject,
} from "./types";

type HttpMethod =
    | "GET"
    | "DELETE"
    | "HEAD"
    | "OPTIONS"
    | "POST"
    | "PUT"
    | "PATCH"
    | "PURGE"
    | "LINK"
    | "UNLINK";

export abstract class BaseApi {
    protected static async request<R, P>(
        method: HttpMethod,
        url: string,
        data?: RequestPayloadDataType | P | string,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse<R>> {
        return axiosInstance.request<R>({
            method,
            url,
            data,
            params,
            ...config,
        });
    }

    public static createCancelTokenSource(): CancelTokenSource {
        return Axios.CancelToken.source();
    }

    protected static async requestPostFormData<R, P>(
        url: string,
        data: RequestPayloadDataType | P | string,
        params: RequestParamsType = {},
        config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse<R>> {
        const postMultiPartConfig: AxiosRequestConfig = {
            transformRequest: [
                (payload: P, headers: SimpleObject<string>) => {
                    headers["Content-Type"] = "multipart/form-data";
                    return payload;
                },
            ],
        };

        return axiosInstance.post<R>(url, data, {
            ...config,
            ...postMultiPartConfig,
            params,
        });
    }
}
