import { useState } from "react";
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useTrans } from "../hooks";
import { APPC, cPath } from "../config";

export const AppPublicFooter = () => {
    const { t } = useTrans();
    const [contentKey, setContentKey] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Modal
                size={"xl"}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                scrollBehavior={"inside"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t(`pcnt.${contentKey}:title`)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{t(`pcnt.${contentKey}:description`)}</ModalBody>
                </ModalContent>
            </Modal>
            <Flex alignItems={"center"} gap={6}>
                <NavLink to={cPath("PUBLIC.HOME_PAGE")}>
                    <Text
                        textStyle={"mediumSm"}
                        _hover={{ textDecoration: "underline" }}
                    >
                        {t("fnav:home")}
                    </Text>
                </NavLink>
                <NavLink to={cPath("PUBLIC.SECURITY.LOGIN_PAGE")}>
                    <Text
                        textStyle={"mediumSm"}
                        _hover={{ textDecoration: "underline" }}
                    >
                        {t("fnav:memberLogin")}
                    </Text>
                </NavLink>
                <NavLink
                    to="#"
                    onClick={() => {
                        setContentKey("privacy");
                        setIsOpen(true);
                    }}
                >
                    <Text
                        textStyle={"mediumSm"}
                        _hover={{ textDecoration: "underline" }}
                    >
                        {t("fnav:privacy")}
                    </Text>
                </NavLink>
                <NavLink
                    to="#"
                    onClick={() => {
                        setContentKey("terms");
                        setIsOpen(true);
                    }}
                >
                    <Text
                        textStyle={"mediumSm"}
                        _hover={{ textDecoration: "underline" }}
                    >
                        {t("fnav:terms")}
                    </Text>
                </NavLink>
            </Flex>
            <Text textStyle={"regularXs"} display={"block"} mt={3}>
                &copy;
                {` ${new Date().getFullYear()} ${APPC.SITE_NAME}`}
            </Text>
        </>
    );
};
