import { Appointment, BaseEntity, Speciality, User } from ".";

export class AppointmentBooking extends BaseEntity {
    public startTime: string = "";
    public endTime: string = "";
    public appointment: string | Appointment = "";
    public status: string = "";
    public userFirstName: string = "";
    public userLastName: string = "";
    public userEmail: string = "";
    public userPhone: string = "";
    public userSpeciality: string | Speciality | null = "";
    public comment: string = "";
    public user: string | User = "";
    public userFullName: string = "";
    public securityCode: string = "";
    public isAppointmentConfirmManual: boolean | null = null;
    public timezone: string = "";

    constructor(model: Partial<AppointmentBooking> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PAppointmentBooking = Partial<AppointmentBooking>;
