import { useEffect, useState } from "react";
import { useAppUtil, useTrans } from "../../../hooks";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import {
    AppFormControl,
    AppIcon,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import { Appointment, AppointmentApi } from "../../../apis";
import {
    Box,
    Text,
    Button,
    HStack,
    Input,
    Select,
    VStack,
    useToast,
    Tag,
    TagLabel,
    TagCloseButton,
    Flex,
    InputGroup,
    InputLeftAddon,
    RadioGroup,
    Radio,
    Divider,
    AbsoluteCenter,
    Switch,
    Stack,
} from "@chakra-ui/react";
import { useAuthData } from "../../../contexts";
import { APPC, cPath } from "../../../config";
import { Calendar } from "react-calendar";
import dayjs from "dayjs";

export const AppointmentSetupPage = () => {
    const { t } = useTrans();
    const toast = useToast();

    const { user } = useAuthData();

    const { getTimeOptions } = useAppUtil();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Appointment>();
    const [timeOptions, setTimeOptions] = useState<string[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [offdays, setOffdays] = useState<string[]>([]);
    const [isScheduleDays, setIsScheduleDays] = useState(true);

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm<Appointment>({
        resolver: yupResolver(schema(t, isScheduleDays)),
        mode: "onSubmit",
    });

    const slotDuration = watch("slotDuration", 30);

    useEffect(() => {
        setTimeOptions(getTimeOptions(slotDuration));
    }, [slotDuration]);

    useEffect(() => {
        reset({
            scheduleDays: watch("scheduleDays"),
            startTime: watch("startTime"),
            endTime: watch("endTime"),
        });
    }, [isScheduleDays]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "availability",
    });

    const onSubmitHandler = (formData: Appointment) => {
        if (isScheduleDays) {
            formData.startTime = null;
            formData.endTime = null;
            formData.scheduleDays = Number(formData.scheduleDays);
        } else {
            formData.scheduleDays = null;
        }
        formData.minimumNoticeTime = Number(formData.minimumNoticeTime);
        formData.appointmentReminderTime = Number(
            formData.appointmentReminderTime
        );
        formData.offDays = offdays;
        if (data?.id) {
            setLoading(true);
            AppointmentApi.patchItem<Appointment, Appointment>(
                data.id,
                formData
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        toast({
                            title: t("cmn:message.save.success"),
                            status: "success",
                        });
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        setLoading(true);
        AppointmentApi.myAppointment<Appointment>()
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setData(response.items[0]);
                    response.items[0]?.availability?.forEach((item) =>
                        append(
                            {
                                day: item.day,
                                start: item.start,
                                end: item.end,
                            },
                            { shouldFocus: false }
                        )
                    );
                    setOffdays(response?.items[0]?.offDays || []);
                    setIsScheduleDays(
                        (response?.items[0]?.scheduleDays &&
                            response?.items[0]?.scheduleDays > 0) ||
                            false
                    );
                }
            })
            .finally(() => setLoading(false));
    }, []);

    const handleCalenderClick = () => {
        if (isCalendarOpen) {
            setSelectedDate("");
        }
        setIsCalendarOpen((prev) => !prev);
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(dayjs(date).format("YYYY-MM-DD"));
    };

    const handleAddOffdaysClick = () => {
        if (selectedDate) {
            if (!offdays.includes(selectedDate)) {
                setOffdays([...offdays, selectedDate]);
            }
            setSelectedDate("");
        }
    };
    const handleDeleteOffday = (index: number) => {
        setOffdays(offdays.filter((_, i) => i !== index));
    };

    const renderPreferences = () => {
        return (
            <>
                <Box w={"full"} mb={4}>
                    <Text textStyle={"heading5"}>
                        {t("padm.AppointmentSetupPage:text.preferences")}
                    </Text>
                </Box>
                <VStack
                    gap={6}
                    bg={"backgroundSecondary"}
                    border={"1px solid"}
                    borderColor={"borderPrimary"}
                    borderRadius={4}
                    p={6}
                    mb={6}
                >
                    {/*
                    <AppFormControl
                        label={t("ent.Appointment:title.label")}
                        isInvalid={!!errors?.title}
                        message={errors?.title?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.Appointment:title.placeholder")}
                            {...register("title")}
                            defaultValue={data?.title}
                            autoFocus
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.Appointment:description.label")}
                        isInvalid={!!errors?.description}
                        message={errors?.description?.message}
                    >
                        <Textarea
                            placeholder={t(
                                "ent.Appointment:description.placeholder"
                            )}
                            {...register("description")}
                            defaultValue={data?.description}
                        />
                    </AppFormControl>
                    */}
                    <Stack
                        gap={4}
                        w={"full"}
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        <AppFormControl
                            label={t("ent.Appointment:slotDuration.label")}
                            labelInfo={t(
                                "padm.AppointmentSetupPage:text.slotDuration.labelInfo"
                            )}
                            isInvalid={!!errors?.slotDuration}
                            message={errors?.slotDuration?.message}
                        >
                            <Select
                                placeholder={t(
                                    "ent.Appointment:slotDuration.placeholder"
                                )}
                                {...register("slotDuration")}
                                defaultValue={data?.slotDuration}
                            >
                                {APPC.BE.SlotDuration?.map((sd) => (
                                    <option key={sd} value={sd}>
                                        {sd}
                                    </option>
                                ))}
                            </Select>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.Appointment:timezone.label")}
                            labelInfo={t(
                                "padm.AppointmentSetupPage:text.timezone.labelInfo"
                            )}
                            isInvalid={!!errors?.timezone}
                            message={errors?.timezone?.message}
                        >
                            <Select
                                placeholder={t(
                                    "ent.Appointment:timezone.placeholder"
                                )}
                                {...register("timezone")}
                                defaultValue={
                                    data?.timezone || APPC.DEFAULT_TIMEZONE
                                }
                            >
                                {Object.keys(APPC.BE.Timezone)?.map((tz) => (
                                    <option key={tz} value={tz}>
                                        {APPC.BE.Timezone[tz]}
                                    </option>
                                ))}
                            </Select>
                        </AppFormControl>
                    </Stack>
                </VStack>
            </>
        );
    };

    const renderSchedule = () => {
        return (
            <>
                <Box w={"full"} mb={4}>
                    <Text textStyle={"heading5"}>
                        {t("padm.AppointmentSetupPage:text.schedule")}
                    </Text>
                </Box>
                <VStack
                    gap={6}
                    bg={"backgroundSecondary"}
                    border={"1px solid"}
                    borderColor={"borderPrimary"}
                    borderRadius={4}
                    p={6}
                    mb={6}
                >
                    <RadioGroup
                        defaultValue={isScheduleDays ? "1" : "2"}
                        w={"full"}
                        onChange={(value) => {
                            setIsScheduleDays(value === "1");
                        }}
                    >
                        <VStack alignItems={"start"} w={"full"}>
                            <Radio value="1">
                                {t(
                                    "padm.AppointmentSetupPage:text.scheduleDays"
                                )}
                            </Radio>
                            <Box p={6}>
                                <AppFormControl
                                    label={t(
                                        "ent.Appointment:scheduleDays.label"
                                    )}
                                    labelInfo={t(
                                        "padm.AppointmentSetupPage:text.scheduleDays.labelInfo"
                                    )}
                                    isInvalid={!!errors?.scheduleDays}
                                    message={errors?.scheduleDays?.message}
                                >
                                    <Select
                                        placeholder={t(
                                            "ent.Appointment:scheduleDays.placeholder"
                                        )}
                                        {...register("scheduleDays")}
                                        defaultValue={data?.scheduleDays || ""}
                                        disabled={!isScheduleDays}
                                    >
                                        {APPC.BE.ScheduleDays?.map((sd) => (
                                            <option key={sd} value={sd}>
                                                {sd}
                                            </option>
                                        ))}
                                    </Select>
                                </AppFormControl>
                            </Box>
                            <Box w={"full"} position="relative" mb={6}>
                                <Divider borderColor={"#cccccc"} />
                                <AbsoluteCenter bg="#f8f8f8" px="4">
                                    {t("padm.AppointmentSetupPage:text.or")}
                                </AbsoluteCenter>
                            </Box>
                            <Radio value="2">
                                {t("padm.AppointmentSetupPage:text.dateRange")}
                            </Radio>
                            <Stack
                                gap={4}
                                p={6}
                                w={"full"}
                                direction={{
                                    base: "column",
                                    md: "row",
                                }}
                            >
                                <AppFormControl
                                    label={t("ent.Appointment:startTime.label")}
                                    labelInfo={t(
                                        "padm.AppointmentSetupPage:text.startTime.labelInfo"
                                    )}
                                    isInvalid={!!errors?.startTime}
                                    message={errors?.startTime?.message}
                                >
                                    <Input
                                        type="date"
                                        placeholder={t(
                                            "ent.Appointment:startTime.placeholder"
                                        )}
                                        {...register("startTime")}
                                        defaultValue={data?.startTime?.substring(
                                            0,
                                            10
                                        )}
                                        disabled={isScheduleDays}
                                    />
                                </AppFormControl>
                                <AppFormControl
                                    label={t("ent.Appointment:endTime.label")}
                                    isInvalid={!!errors?.endTime}
                                    message={errors?.endTime?.message}
                                >
                                    <Input
                                        type="date"
                                        placeholder={t(
                                            "ent.Appointment:endTime.placeholder"
                                        )}
                                        {...register("endTime")}
                                        defaultValue={data?.endTime?.substring(
                                            0,
                                            10
                                        )}
                                        disabled={isScheduleDays}
                                    />
                                </AppFormControl>
                            </Stack>
                        </VStack>
                    </RadioGroup>
                </VStack>
            </>
        );
    };

    const renderAvailability = () => {
        return (
            <>
                <Box w={"full"} mb={4}>
                    <HStack w={"full"} justifyContent={"space-between"}>
                        <Text textStyle={"heading5"}>
                            {t("padm.AppointmentSetupPage:text.availability")}
                        </Text>
                        <Button
                            type="button"
                            size={"sm"}
                            variant={"outline"}
                            onClick={() =>
                                append({
                                    day: 1,
                                    start: "",
                                    end: "",
                                })
                            }
                        >
                            <AppIcon name="ics-plus-circle" w="1rem" />
                            &nbsp;&nbsp;{t("cmn:button.add")}
                        </Button>
                    </HStack>
                </Box>
                <Box overflowX="auto">
                    <VStack
                        gap={6}
                        bg={"backgroundSecondary"}
                        border={"1px solid"}
                        borderColor={"borderPrimary"}
                        borderRadius={4}
                        p={6}
                        mb={6}
                        minW={"530px"}
                    >
                        {fields.map((availability, index) => (
                            <HStack
                                key={availability.id}
                                w={"full"}
                                alignItems={"start"}
                            >
                                <AppFormControl
                                    label={
                                        !index
                                            ? t(
                                                  "ent.Appointment:availability.day.label"
                                              )
                                            : undefined
                                    }
                                    isInvalid={
                                        !!errors?.availability?.[index]?.["day"]
                                    }
                                    message={
                                        errors?.availability?.[index]?.["day"]
                                            ?.message
                                    }
                                >
                                    <Select
                                        // id={
                                        //     `availability.${index}.day` as const
                                        // }
                                        placeholder={t(
                                            "ent.Appointment:availability.day.placeholder"
                                        )}
                                        {...register(
                                            `availability.${index}.day` as const
                                        )}
                                        size={"sm"}
                                    >
                                        {Object.keys(APPC.BE.Weekdays)?.map(
                                            (wd) => (
                                                <option
                                                    key={wd}
                                                    value={`${wd}`}
                                                >
                                                    {APPC.BE.Weekdays[wd]}
                                                </option>
                                            )
                                        )}
                                    </Select>
                                </AppFormControl>
                                <AppFormControl
                                    label={
                                        !index
                                            ? t(
                                                  "ent.Appointment:availability.start.label"
                                              )
                                            : undefined
                                    }
                                    isInvalid={
                                        !!errors?.availability?.[index]?.[
                                            "start"
                                        ]
                                    }
                                    message={
                                        errors?.availability?.[index]?.["start"]
                                            ?.message
                                    }
                                >
                                    <Select
                                        // id={
                                        //     `availability.${index}.start` as const
                                        // }
                                        placeholder={t(
                                            "ent.Appointment:availability.start.placeholder"
                                        )}
                                        {...register(
                                            `availability.${index}.start` as const
                                        )}
                                        size={"sm"}
                                    >
                                        {timeOptions.slice(0, -1).map((tm) => (
                                            <option key={tm} value={tm}>
                                                {tm}
                                            </option>
                                        ))}
                                    </Select>
                                </AppFormControl>
                                <AppFormControl
                                    label={
                                        !index
                                            ? t(
                                                  "ent.Appointment:availability.end.label"
                                              )
                                            : undefined
                                    }
                                    isInvalid={
                                        !!errors?.availability?.[index]?.["end"]
                                    }
                                    message={
                                        errors?.availability?.[index]?.["end"]
                                            ?.message
                                    }
                                >
                                    <Select
                                        // id={
                                        //     `availability.${index}.end` as const
                                        // }
                                        placeholder={t(
                                            "ent.Appointment:availability.end.placeholder"
                                        )}
                                        {...register(
                                            `availability.${index}.end` as const
                                        )}
                                        size={"sm"}
                                    >
                                        {timeOptions.slice(1).map((tm) => (
                                            <option key={tm} value={tm}>
                                                {tm}
                                            </option>
                                        ))}
                                    </Select>
                                </AppFormControl>
                                <Button
                                    type="button"
                                    variant={"destructive"}
                                    className="btn-icon-sm"
                                    onClick={() => remove(index)}
                                    alignSelf={"flex-end"}
                                    h={"full"}
                                    size={"sm"}
                                >
                                    <AppIcon name="icl-trash" w="1.08rem" />
                                </Button>
                            </HStack>
                        ))}
                    </VStack>
                </Box>
            </>
        );
    };

    const renderOffDays = () => {
        return (
            <>
                <Box overflowX="auto">
                    <Box w={"full"} mb={4}>
                        <Text textStyle={"heading5"}>
                            {t("padm.AppointmentSetupPage:text.offDays")}
                        </Text>
                    </Box>
                    <VStack
                        gap={6}
                        bg={"backgroundSecondary"}
                        border={"1px solid"}
                        borderColor={"borderPrimary"}
                        borderRadius={4}
                        p={6}
                        mb={6}
                        minW={"530px"}
                    >
                        {offdays.length > 0 && (
                            <Flex
                                w={"full"}
                                justifyContent={"flex-start"}
                                rowGap={2}
                                columnGap={2}
                                flexWrap={"wrap"}
                                border={"1px solid #AFAFAF"}
                                borderRadius={4}
                                p={2}
                            >
                                {offdays?.map((offday, index) => (
                                    <Tag
                                        key={index}
                                        size={"md"}
                                        variant="solid"
                                        borderRadius={2}
                                    >
                                        <TagLabel>
                                            {dayjs(offday).format("DD/MM/YYYY")}
                                        </TagLabel>
                                        <TagCloseButton
                                            onClick={() =>
                                                handleDeleteOffday(index)
                                            }
                                        />
                                    </Tag>
                                ))}
                            </Flex>
                        )}
                        <HStack w={"full"}>
                            <InputGroup
                                size={"sm"}
                                onClick={handleCalenderClick}
                                maxW={"265px"}
                            >
                                <InputLeftAddon>
                                    <AppIcon name="ics-calendar" w="1.2rem" />
                                </InputLeftAddon>
                                <Input
                                    id="offDays"
                                    size={"sm"}
                                    value={selectedDate ?? ""}
                                    readOnly
                                />
                            </InputGroup>
                            <Button
                                size={"sm"}
                                type="button"
                                variant={"outline"}
                                onClick={handleAddOffdaysClick}
                                isDisabled={!selectedDate}
                            >
                                <AppIcon name="ics-plus-circle" w="1rem" />
                                &nbsp;&nbsp;{t("cmn:button.add")}
                            </Button>
                        </HStack>
                        {isCalendarOpen && (
                            <Box w={"full"}>
                                <Calendar
                                    minDate={new Date()}
                                    value={
                                        selectedDate
                                            ? new Date(selectedDate)
                                            : new Date()
                                    }
                                    onChange={(value) =>
                                        handleDateChange(value as Date)
                                    }
                                />
                            </Box>
                        )}
                    </VStack>
                </Box>
            </>
        );
    };

    const renderOtherSetting = () => {
        return (
            <>
                <Box w={"full"} mb={4}>
                    <Text textStyle={"heading5"}>
                        {t("padm.AppointmentSetupPage:text.otherSettings")}
                    </Text>
                </Box>
                <VStack
                    gap={6}
                    bg={"backgroundSecondary"}
                    border={"1px solid"}
                    borderColor={"borderPrimary"}
                    borderRadius={4}
                    p={6}
                    mb={6}
                >
                    <AppFormControl
                        label={t("ent.Appointment:minimumNoticeTime.label")}
                        labelInfo={t(
                            "ent.Appointment:minimumNoticeTime.labelInfo"
                        )}
                        isInvalid={!!errors.minimumNoticeTime}
                        message={errors.minimumNoticeTime?.message}
                    >
                        <HStack>
                            <Select
                                placeholder={t(
                                    "ent.Appointment:minimumNoticeTime.placeholder"
                                )}
                                {...register("minimumNoticeTime")}
                                defaultValue={data?.minimumNoticeTime}
                                maxW={"160px"}
                                size={"sm"}
                            >
                                {Object.keys(APPC.BE.NoticeTime)?.map((nt) => (
                                    <option key={nt} value={`${nt}`}>
                                        {APPC.BE.NoticeTime[nt]}
                                    </option>
                                ))}
                            </Select>
                        </HStack>
                    </AppFormControl>
                    <AppFormControl
                        label={t(
                            "ent.Appointment:appointmentReminderTime.label"
                        )}
                        labelInfo={t(
                            "ent.Appointment:appointmentReminderTime.labelInfo"
                        )}
                        isInvalid={!!errors.appointmentReminderTime}
                        message={errors.appointmentReminderTime?.message}
                    >
                        <HStack>
                            <Switch
                                {...register("isAppointmentReminder")}
                                defaultChecked={!!data?.isAppointmentReminder}
                                size={"lg"}
                                mr={6}
                            />
                            <Select
                                id="appointmentReminderTime"
                                placeholder={t(
                                    "ent.Appointment:appointmentReminderTime.placeholder"
                                )}
                                {...register("appointmentReminderTime")}
                                defaultValue={data?.appointmentReminderTime}
                                maxW={"160px"}
                                size={"sm"}
                            >
                                {Object.keys(APPC.BE.ReminderTime)?.map(
                                    (rt) => (
                                        <option key={rt} value={`${rt}`}>
                                            {APPC.BE.ReminderTime[rt]}
                                        </option>
                                    )
                                )}
                            </Select>
                        </HStack>
                    </AppFormControl>
                    <AppFormControl
                        label={t(
                            "ent.Appointment:isAppointmentConfirmManual.label"
                        )}
                        labelInfo={t(
                            "ent.Appointment:isAppointmentConfirmManual.labelInfo"
                        )}
                        isInvalid={!!errors.isAppointmentConfirmManual}
                        message={errors.isAppointmentConfirmManual?.message}
                    >
                        <HStack>
                            <Switch
                                {...register("isAppointmentConfirmManual")}
                                defaultChecked={
                                    !!data?.isAppointmentConfirmManual
                                }
                                size={"lg"}
                                mr={6}
                            />
                        </HStack>
                    </AppFormControl>
                    {/*
                    <AppFormControl
                        label={t("ent.Appointment:isCancelAllow.label")}
                        labelInfo={t("ent.Appointment:isCancelAllow.labelInfo")}
                        isInvalid={!!errors.isCancelAllow}
                        message={errors.isCancelAllow?.message}
                    >
                        <HStack>
                            <Switch
                                {...register("isCancelAllow")}
                                defaultChecked={!!data?.isCancelAllow}
                                size={"lg"}
                                mr={6}
                            />
                        </HStack>
                    </AppFormControl>
                    */}
                    <AppFormControl
                        label={t("ent.Appointment:isAppointmentActive.label")}
                        labelInfo={t(
                            "ent.Appointment:isAppointmentActive.labelInfo"
                        )}
                        isInvalid={!!errors.isAppointmentActive}
                        message={errors.isAppointmentActive?.message}
                    >
                        <HStack>
                            <Switch
                                {...register("isAppointmentActive")}
                                defaultChecked={!!data?.isAppointmentActive}
                                size={"lg"}
                                mr={6}
                            />
                        </HStack>
                    </AppFormControl>
                </VStack>
            </>
        );
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
                style={{ maxWidth: "600px" }}
            >
                {renderPreferences()}
                {renderSchedule()}
                {renderAvailability()}
                {renderOffDays()}
                {renderOtherSetting()}
                <Box w={"full"} textAlign={"end"}>
                    <Button type="submit" form="app-form">
                        {t("cmn:button.save")}
                    </Button>
                </Box>
            </form>
        );
    };

    return (
        <>
            <AppPageHeader title={t("padm.AppointmentSetupPage:text.setup")}>
                <Button
                    variant={"primary"}
                    onClick={() =>
                        window.open(
                            cPath(
                                "PUBLIC.DETAIL_PAGE",
                                { id: `${user?.id}` },
                                false
                            ),
                            "_blank"
                        )
                    }
                >
                    <AppIcon name="ics-eye" w="1rem" />
                    <Text ml={2}>
                        {t("padm.AppointmentSetupPage:button.preview")}
                    </Text>
                </Button>
            </AppPageHeader>
            {loading ? <AppLoader /> : <Box p={6}>{renderUserForm()}</Box>}
        </>
    );
};
