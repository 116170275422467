const typography = {
    fonts: {
        heading: "Inter Bold, sans-serif",
        body: "Inter Regular, sans-serif",
    },
    textStyles: {
        heading1: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "4rem",
            lineHeight: "4.8rem",
        },
        heading2: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "2rem",
            lineHeight: "2.4rem",
        },
        heading3: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1.75rem",
            lineHeight: "2.1rem",
        },
        heading4: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1.5rem",
            lineHeight: "1.8rem",
        },
        heading5: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
        },

        regularXs: {
            fontFamily: "Inter Regular, sans-serif",
            fontSize: "0.75rem",
            lineHeight: "1.05rem",
        },
        mediumXs: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "0.75rem",
            lineHeight: "1.05rem",
        },
        semiBoldXs: {
            fontFamily: "Inter SemiBold, sans-serif",
            fontSize: "0.75rem",
            lineHeight: "1.05rem",
        },
        boldXs: {
            fontFamily: "Inter Bold, sans-serif",
            fontSize: "0.75rem",
            lineHeight: "1.05rem",
        },

        regularSm: {
            fontFamily: "Inter Regular, sans-serif",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        mediumSm: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        semiBoldSm: {
            fontFamily: "Inter SemiBold, sans-serif",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },
        boldSm: {
            fontFamily: "Inter Bold, sans-serif",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
        },

        regularMd: {
            fontFamily: "Inter Regular, sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        mediumMd: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        semiBoldMd: {
            fontFamily: "Inter SemiBold, sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },
        boldMd: {
            fontFamily: "Inter Bold, sans-serif",
            fontSize: "1rem",
            lineHeight: "1.5rem",
        },

        regularLg: {
            fontFamily: "Inter Regular, sans-serif",
            fontSize: "1.125rem",
            lineHeight: "1.6875rem",
        },
        mediumLg: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1.125rem",
            lineHeight: "1.6875rem",
        },
        semiBoldLg: {
            fontFamily: "Inter SemiBold, sans-serif",
            fontSize: "1.125rem",
            lineHeight: "1.6875rem",
        },
        boldLg: {
            fontFamily: "Inter Bold, sans-serif",
            fontSize: "1.125rem",
            lineHeight: "1.6875rem",
        },

        regularXl: {
            fontFamily: "Inter Regular, sans-serif",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
        },
        mediumXl: {
            fontFamily: "Inter Medium, sans-serif",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
        },
        semiBoldXl: {
            fontFamily: "Inter SemiBold, sans-serif",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
        },
        boldXl: {
            fontFamily: "Inter Bold, sans-serif",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
        },
    },
};

export default typography;
