import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse } from "../../services/ApiPlatform/models";

export abstract class DashboardApi extends EntityApi {
    protected static API_DASHBOARD_MEMBER = "/api/dashboard/member";

    public static async dashboardMember<R>(): Promise<FinalResponse<R | null>> {
        return this.getRequest(this.API_DASHBOARD_MEMBER);
    }
}
