import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
    DashboardPage,
    ForgotPasswordPage,
    LoginPage,
    ProfilePage,
    SetPasswordPage,
    UserPage,
    HomePage,
    CategoryPage,
    SpecialityPage,
    DetailPage,
    AppointmentBookingPage,
    AppointmentSetupPage,
} from "../pages";
import { useAuthAction } from "../hooks";
import { cPath } from "../config";
import PublicLayout from "../layout/PublicLayout";
import { AppLoader } from "../components";

const AdminLayout = lazy(() => import("../layout/AdminLayout"));
const LoginLayout = lazy(() => import("../layout/LoginLayout"));

const Logout = () => {
    const { logoutAction } = useAuthAction();
    useEffect(() => {
        logoutAction();
    }, []);
    return <></>;
};

const RootRoutes = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
    return (
        <Routes>
            <Route
                path="*"
                element={<Navigate to={cPath("PUBLIC")} replace />}
            />
            <Route
                path={cPath("PUBLIC")}
                element={
                    <Suspense fallback={<AppLoader />}>
                        <PublicLayout />
                    </Suspense>
                }
            >
                <Route
                    path={cPath("PUBLIC.HOME_PAGE", {}, true)}
                    element={<HomePage />}
                />
                <Route
                    path={cPath("PUBLIC.DETAIL_PAGE", {}, true)}
                    element={<DetailPage />}
                />
            </Route>
            <Route
                path={cPath("PUBLIC")}
                element={
                    <Suspense fallback={<AppLoader />}>
                        <LoginLayout />
                    </Suspense>
                }
            >
                <Route
                    path={cPath("PUBLIC.SECURITY.LOGIN_PAGE", {}, true)}
                    element={<LoginPage />}
                />
                <Route
                    path={cPath(
                        "PUBLIC.SECURITY.FORGOT_PASSWORD_PAGE",
                        {},
                        true
                    )}
                    element={<ForgotPasswordPage />}
                />
                <Route
                    path={cPath("PUBLIC.SECURITY.SET_PASSWORD_PAGE", {}, true)}
                    element={<SetPasswordPage />}
                />
            </Route>
            {isAuthenticated && (
                <>
                    <Route
                        path={cPath("ADMIN")}
                        element={
                            <Suspense fallback={<AppLoader />}>
                                <AdminLayout />
                            </Suspense>
                        }
                    >
                        <Route
                            path={cPath("ADMIN.DASHBOARD_PAGE", {}, true)}
                            element={<DashboardPage />}
                        />
                        <Route
                            path={cPath("ADMIN.PROFILE_PAGE", {}, true)}
                            element={<ProfilePage />}
                        />

                        <Route
                            path={cPath("ADMIN.USER_PAGE", {}, true)}
                            element={<UserPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.USER_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<UserPage />}
                        />

                        <Route
                            path={cPath("ADMIN.CATEGORY_PAGE", {}, true)}
                            element={<CategoryPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.CATEGORY_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<CategoryPage />}
                        />

                        <Route
                            path={cPath("ADMIN.SPECIALITY_PAGE", {}, true)}
                            element={<SpecialityPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.SPECIALITY_PAGE_EDIT",
                                { id: ":id" },
                                true
                            )}
                            element={<SpecialityPage />}
                        />

                        <Route
                            path={cPath(
                                "ADMIN.APPOINTMENT_BOOKING_PAGE",
                                {},
                                true
                            )}
                            element={<AppointmentBookingPage />}
                        />
                        <Route
                            path={cPath(
                                "ADMIN.APPOINTMENT_SETUP_PAGE",
                                {},
                                true
                            )}
                            element={<AppointmentSetupPage />}
                        />
                        <Route
                            path={cPath("ADMIN.LOGOUT", {}, true)}
                            element={<Logout />}
                        />
                    </Route>
                </>
            )}
        </Routes>
    );
};

export default RootRoutes;
