export class BaseEntity {
    id: number;

    constructor(id?: number) {
        this.id = id || 1;
    }

    getId(): number {
        if (this.id === undefined) {
            throw new Error(
                "Before initialization, you're trying to access `id`"
            );
        }
        return this.id;
    }
}
