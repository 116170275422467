import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse } from "../../services/ApiPlatform/models";

export abstract class AppointmentBookingApi extends EntityApi {
    protected static GET_COLLECTION = "/api/appointment_bookings";

    protected static POST_COLLECTION = "/appointment_bookings";

    protected static POST_COLLECTION_APPOINTMENT_CHECK =
        "/appointment_bookings/check";

    protected static GET_ITEM = "/api/appointment_bookings/{id}";

    protected static PUT_ITEM = "/api/appointment_bookings/{id}";

    protected static PATCH_ITEM = "/api/appointment_bookings/{id}";

    protected static PATCH_ITEM_CHANGE_STATUS =
        "/api/appointment_bookings/{id}/change-status";

    protected static DELETE_ITEM = "/api/appointment_bookings/{id}";

    public static async appointmentCheck<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.POST_COLLECTION_APPOINTMENT_CHECK,
        };
        return this.postCollection(entity, extraParams);
    }

    public static async changeStatus<R, P>(
        id: number | string,
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.PATCH_ITEM_CHANGE_STATUS,
        };
        return this.patchItem(id, entity, extraParams);
    }
}
