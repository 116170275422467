import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { ACCEPTABLE_RESPONSE, REQUEST_ACCEPT_HEADERS } from "../config";
import { LSC } from "../../../config";

export const onRequestFulfilled = (
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
    config.headers["Content-Type"] =
        REQUEST_ACCEPT_HEADERS[ACCEPTABLE_RESPONSE];
    const token = localStorage.getItem(LSC.APP_TOKEN_KEY);
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
};

export const onRequestRejected = (error: AxiosError) => Promise.reject(error);
