import { useEffect } from "react";
import { useAuthAction } from "./hooks";
import RootRoutes from "./routes/RootRoutes";

function App() {
    const { isAuthenticated, reloadUserAction } = useAuthAction();

    useEffect(() => {
        if (isAuthenticated) {
            reloadUserAction();
        }
    }, [isAuthenticated]);

    return <RootRoutes isAuthenticated={isAuthenticated} />;
}

export default App;
