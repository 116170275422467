import { FC, useEffect, useRef, useState, Suspense, lazy } from "react";
import { CropperRef } from "react-advanced-cropper";
import {
    FormContainerStateReturnType,
    useAppUtil,
    useResolveUploadedPath,
    useTrans,
} from "../../../hooks";
import {
    VStack,
    Input,
    useToast,
    Textarea,
    Select,
    Avatar,
    Box,
    Button,
    HStack,
} from "@chakra-ui/react";
import {
    User as FormEntity,
    UserApi as FormEntityApi,
    Category,
    CategoryApi,
    UploadApi,
} from "../../../apis";
import { AppFormControl, AppIcon, AppLoader } from "../../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { AppFormContainer } from "../../../containers";
import { APPC } from "../../../config";
import "react-advanced-cropper/dist/style.css";

const Cropper = lazy(() =>
    import("react-advanced-cropper").then((module) => ({
        default: module.Cropper,
    }))
);

export const UserForm: FC<{
    formContainerState: FormContainerStateReturnType;
}> = ({ formContainerState }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const { getFileFromBlob } = useAppUtil();

    // state & const
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FormEntity>(new FormEntity());
    const [category, setCategory] = useState<Category[]>();
    const [uploadedFile, setUploadedFile] = useState<File | undefined>(
        undefined
    );
    const [croppedFile, setCroppedFile] = useState<File | undefined>(undefined);
    const isEditMode: boolean = !!formContainerState.entityId;

    // cropper
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<CropperRef>(null);
    const onCropperDone = async () => {
        if (cropperRef?.current && uploadedFile) {
            await cropperRef?.current?.getCanvas()?.toBlob(
                async (blob) => {
                    if (blob) {
                        setCroppedFile(getFileFromBlob(blob, uploadedFile));
                    }
                },
                uploadedFile.type,
                1
            );
            setShowCropper(false);
        }
    };
    const onRotate = async (isCcw = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.rotateImage(isCcw ? 90 : -90);
        }
    };
    const onZoom = async (isZoomOut = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.zoomImage(isZoomOut ? 0.5 : 2);
        }
    };

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t, isEditMode)),
        mode: "onSubmit",
    });

    const onSubmitHandler = async (formData: FormEntity) => {
        setLoading(true);
        if (croppedFile) {
            if (showCropper) {
                await onCropperDone();
            }
            const uploadFormData = new FormData();
            uploadFormData.set("fileType", APPC.BE.Upload.FILETYPE_USER_AVATAR);
            uploadFormData.set("file", croppedFile);

            await UploadApi.upload<{ fileName: string }, FormData>(
                uploadFormData
            ).then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    formData.avatar = response.fileName;
                }
            });
        }

        if (formData.category == "") {
            formData.category = null;
        }

        FormEntityApi.createOrUpdate<FormEntity, FormEntity>(
            formContainerState.entityId,
            formData
        )
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    toast({
                        title: t("cmn:message.save.success"),
                        status: "success",
                    });
                    reset();
                    formContainerState.close();
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        CategoryApi.getCollection<Category>(1, {
            pagination: false,
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setCategory(response.items);
                }
            })
            .finally(() => {
                if (formContainerState.entityId) {
                    FormEntityApi.getItem<FormEntity>(
                        formContainerState.entityId
                    )
                        .then(({ errorMessage, isNotFound, response }) => {
                            if (errorMessage) {
                                toast({
                                    title: errorMessage,
                                    status: "error",
                                });
                            } else if (isNotFound) {
                                toast({
                                    title: t("cmn:message.entityNotFound"),
                                    status: "error",
                                });
                            } else if (response !== null) {
                                reset();
                                setData(response);
                            }
                        })
                        .finally(() => setLoading(false));
                } else {
                    setLoading(false);
                }
            });
    }, [formContainerState.entityId]);

    return (
        <>
            {loading ? (
                <AppLoader />
            ) : (
                <AppFormContainer
                    title={t("padm.UserForm:text.user")}
                    state={formContainerState}
                    type={APPC.FORM_CONTAINER}
                >
                    <form
                        id="app-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(onSubmitHandler)();
                        }}
                    >
                        <VStack gap={5} alignItems={"stretch"}>
                            <AppFormControl label={t("ent.User:avatar.label")}>
                                <Box textAlign={"center"}>
                                    <Avatar
                                        size={"xl"}
                                        name={data?.fullNameNs}
                                        src={useResolveUploadedPath(
                                            APPC.BE.Upload.FILETYPE_USER_AVATAR,
                                            data?.avatar,
                                            croppedFile
                                        )}
                                    >
                                        <Box
                                            display={"flex"}
                                            pos={"absolute"}
                                            bottom={2}
                                            right={2}
                                            bg="#fff"
                                            borderRadius={"full"}
                                            padding={1}
                                        >
                                            <AppIcon
                                                name="icl-pencil"
                                                color="#000"
                                                w="1rem"
                                            />
                                        </Box>
                                    </Avatar>
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files?.[0];
                                            if (file) {
                                                setUploadedFile(file);
                                                setShowCropper(true);
                                            }
                                            e.target.value = "";
                                        }}
                                        opacity={0}
                                        position={"absolute"}
                                        w={"full"}
                                        h={"full"}
                                        top={0}
                                        left={0}
                                    />
                                    {showCropper && uploadedFile && (
                                        <Box
                                            border={"1px solid #000"}
                                            p={4}
                                            mt={4}
                                        >
                                            <Suspense
                                                fallback={<div>Loading...</div>}
                                            >
                                                <Cropper
                                                    ref={cropperRef}
                                                    src={URL.createObjectURL(
                                                        uploadedFile
                                                    )}
                                                    className={"cropper"}
                                                    stencilProps={{
                                                        aspectRatio:
                                                            APPC.BE.Upload
                                                                .FILETYPEINFO_USER_AVATAR
                                                                .ratio,
                                                    }}
                                                />
                                            </Suspense>
                                            <HStack
                                                justify={"space-between"}
                                                mt={4}
                                                wrap={"wrap"}
                                            >
                                                <HStack spacing={2}>
                                                    <Button
                                                        variant={"outline"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={() => onZoom()}
                                                    >
                                                        <AppIcon
                                                            name="icl-zoom-in"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant={"outline"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={() =>
                                                            onZoom(true)
                                                        }
                                                    >
                                                        <AppIcon
                                                            name="icl-zoom-out"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant={"outline"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={() =>
                                                            onRotate()
                                                        }
                                                    >
                                                        <AppIcon
                                                            name="icl-action-undo"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant={"outline"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={() =>
                                                            onRotate(true)
                                                        }
                                                    >
                                                        <AppIcon
                                                            name="icl-action-redo"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                </HStack>
                                                <HStack spacing={2}>
                                                    <Button
                                                        variant={"outline"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={() => {
                                                            setUploadedFile(
                                                                undefined
                                                            );
                                                            setShowCropper(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        <AppIcon
                                                            name="icl-x"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                    <Button
                                                        variant={"primary"}
                                                        size={"sm"}
                                                        className="btn-icon-sm"
                                                        onClick={onCropperDone}
                                                    >
                                                        <AppIcon
                                                            name="icl-check-alt"
                                                            w="1rem"
                                                        />
                                                    </Button>
                                                </HStack>
                                            </HStack>
                                        </Box>
                                    )}
                                </Box>
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:salutation.label")}
                                isInvalid={!!errors?.salutation}
                                message={errors?.salutation?.message}
                            >
                                <Select
                                    placeholder={t(
                                        "ent.User:salutation.placeholder"
                                    )}
                                    {...register("salutation")}
                                    defaultValue={data?.salutation}
                                >
                                    {APPC.BE.Salutation?.map((sl) => (
                                        <option key={sl} value={sl}>
                                            {sl}
                                        </option>
                                    ))}
                                </Select>
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:firstName.label")}
                                isInvalid={!!errors?.firstName}
                                message={errors?.firstName?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.User:firstName.placeholder"
                                    )}
                                    {...register("firstName")}
                                    defaultValue={data.firstName}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:lastName.label")}
                                isInvalid={!!errors?.lastName}
                                message={errors?.lastName?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.User:lastName.placeholder"
                                    )}
                                    {...register("lastName")}
                                    defaultValue={data.lastName}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:email.label")}
                                isInvalid={!!errors?.email}
                                message={errors?.email?.message}
                            >
                                <Input
                                    type="email"
                                    placeholder={t(
                                        "ent.User:email.placeholder"
                                    )}
                                    {...register("email")}
                                    defaultValue={data.email}
                                    disabled={isEditMode}
                                    autoComplete="off"
                                />
                            </AppFormControl>
                            {!isEditMode && (
                                <>
                                    <AppFormControl
                                        label={t(
                                            "ent.User:plainPassword.label"
                                        )}
                                        isInvalid={!!errors?.plainPassword}
                                        message={errors?.plainPassword?.message}
                                    >
                                        <Input
                                            type="password"
                                            placeholder={t(
                                                "ent.User:plainPassword.placeholder"
                                            )}
                                            {...register("plainPassword")}
                                        />
                                    </AppFormControl>
                                    <AppFormControl
                                        label={t(
                                            "ent.User:plainPasswordConfirm.label"
                                        )}
                                        isInvalid={
                                            !!errors?.plainPasswordConfirm
                                        }
                                        message={
                                            errors?.plainPasswordConfirm
                                                ?.message
                                        }
                                    >
                                        <Input
                                            type="password"
                                            placeholder={t(
                                                "ent.User:plainPasswordConfirm.placeholder"
                                            )}
                                            {...register(
                                                "plainPasswordConfirm"
                                            )}
                                        />
                                    </AppFormControl>
                                </>
                            )}
                            <AppFormControl
                                label={t("ent.User:category.label")}
                                isInvalid={!!errors?.category}
                                message={errors?.category?.message}
                            >
                                <Select
                                    placeholder={t(
                                        "ent.User:category.placeholder"
                                    )}
                                    {...register("category")}
                                    defaultValue={data?.category?.["@id"]}
                                >
                                    {category?.map((c) => (
                                        <option key={c.id} value={c["@id"]}>
                                            {c.name}
                                        </option>
                                    ))}
                                </Select>
                            </AppFormControl>
                            <AppFormControl
                                label={t("ent.User:phone.label")}
                                isInvalid={!!errors?.phone}
                                message={errors?.phone?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.User:phone.placeholder"
                                    )}
                                    {...register("phone")}
                                    defaultValue={data.phone}
                                    autoComplete="off"
                                />
                            </AppFormControl>
                            {!isEditMode && (
                                <AppFormControl
                                    label={t("ent.User:timezone.label")}
                                    isInvalid={!!errors?.timezone}
                                    message={errors?.timezone?.message}
                                >
                                    <Select
                                        placeholder={t(
                                            "ent.User:timezone.placeholder"
                                        )}
                                        {...register("timezone")}
                                        defaultValue={
                                            data?.timezone ||
                                            APPC.DEFAULT_TIMEZONE
                                        }
                                    >
                                        {Object.keys(APPC.BE.Timezone)?.map(
                                            (tz) => (
                                                <option key={tz} value={tz}>
                                                    {APPC.BE.Timezone[tz]}
                                                </option>
                                            )
                                        )}
                                    </Select>
                                </AppFormControl>
                            )}
                            <AppFormControl
                                label={t("ent.User:bioData.label")}
                                isInvalid={!!errors?.bioData}
                                message={errors?.bioData?.message}
                            >
                                <Textarea
                                    placeholder={t(
                                        "ent.User:bioData.placeholder"
                                    )}
                                    {...register("bioData")}
                                    defaultValue={data.bioData}
                                />
                            </AppFormControl>
                        </VStack>
                    </form>
                </AppFormContainer>
            )}
        </>
    );
};
