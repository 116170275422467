import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
    fontFamily: "Inter Medium, sans-serif",
    fontWeight: 500,
    borderRadius: "0.25rem",
});

const sizes = {
    md: defineStyle({
        h: "1.5625rem",
        px: 1.5,
        py: 1,
        fontSize: "0.75rem",
        lineHeight: "1.0625rem",
        textTransform: "none",
    }),
    lg: defineStyle({
        h: "2.0625rem",
        px: 2,
        py: 1.5,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
        textTransform: "none",
    }),
};

const variantGreen = defineStyle({
    bg: "greenBadgeBg",
    color: "greenBadgeText",
});

const variantBlue = defineStyle({
    bg: "blueBadgeBg",
    color: "blueBadgeText",
});

const variantRed = defineStyle({
    bg: "redBadgeBg",
    color: "redBadgeText",
});

const variantPrimary = defineStyle({
    bg: "primaryBadgeBg",
    color: "primaryBadgeText",
});

const variantSecondary = defineStyle({
    bg: "secondaryBadgeBg",
    color: "secondaryBadgeText",
});

const variants = {
    green: variantGreen,
    blue: variantBlue,
    red: variantRed,
    primary: variantPrimary,
    secondary: variantSecondary,
};

export const Badge = defineStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "green",
    },
});
