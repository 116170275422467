import { AppDrawerForm } from "./AppDrawerForm";
import { AppModalForm } from "./AppModalForm";
import { FormContainerStateReturnType } from "../../hooks";

export type AppFormContainerProps = {
    title: string;
    state: FormContainerStateReturnType;
    children: JSX.Element;
    type?: "drawer" | "modal";
    size?: string;
};

export const AppFormContainer = ({
    title,
    state,
    children,
    type = "drawer",
    size = "md",
}: AppFormContainerProps) => {
    if (type === "modal") {
        return (
            <AppModalForm title={title} state={state} size={size}>
                {children}
            </AppModalForm>
        );
    }

    return (
        <AppDrawerForm title={title} state={state} size={size}>
            {children}
        </AppDrawerForm>
    );
};
