import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse, ListResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class UserApi extends EntityApi {
    protected static GET_COLLECTION = "/api/users";

    protected static POST_COLLECTION = "/api/users";

    protected static GET_ITEM = "/api/users/{id}";

    protected static PUT_ITEM = "/api/users/{id}";

    protected static PATCH_ITEM = "/api/users/{id}";

    protected static DELETE_ITEM = "/api/users/{id}";

    protected static POST_USER_EXIST = "/api/user-exist";

    protected static POST_USER_CHANGE_PASSWORD_REQUEST =
        "/user-change-password-request";

    protected static POST_USER_SET_PASSWORD = "/user-set-password";

    protected static PATCH_USER_ITEM_PROFILE = "/api/users/{id}/profile";

    protected static GET_COLLECTION_GET_USERS = "/get-users";

    protected static GET_ITEM_GET_USER_DETAIL = "/get-users/{id}";

    protected static GET_ITEM_GET_SLOT = "/get-users/{id}/slot/{date}";

    public static async getUsers<R>(
        page = 1,
        extraParams = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        extraParams = { ...extraParams, PATH: this.GET_COLLECTION_GET_USERS };
        return this.getCollection(page, extraParams, cancelToken);
    }

    public static async getUserDetail<R>(
        id: number | string,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.routeToPath(this.GET_ITEM_GET_USER_DETAIL, { id }),
        };
        return this.getItem(id, extraParams);
    }

    public static async getSlot<R>(
        id: number | string,
        date: string,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.routeToPath(this.GET_ITEM_GET_SLOT, {
                id,
                date,
            }),
        };
        return this.getItem(id, extraParams);
    }

    public static async postUserExist<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.POST_USER_EXIST };
        return this.postCollection(entity, extraParams);
    }

    public static async postUserChangePasswordRequest<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.POST_USER_CHANGE_PASSWORD_REQUEST,
        };
        return this.postCollection(entity, extraParams);
    }

    public static async postUserSetPassword<R, P>(
        entity: P,
        extraParams = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = {
            ...extraParams,
            PATH: this.POST_USER_SET_PASSWORD,
        };
        return this.postCollection(entity, extraParams);
    }

    public static async patchItemProfile<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.PATCH_USER_ITEM_PROFILE };
        return this.patchItem(id, entity, extraParams);
    }
}
