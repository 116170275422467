import { useNavigate } from "react-router-dom";
import { Avatar, Button, Flex, HStack, Text } from "@chakra-ui/react";
import { User } from "../apis";
import { AppIcon } from "./AppIcon";
import { APPC, cPath } from "../config";
import { useResolveUploadedPath, useTrans } from "../hooks";

interface AppUserRowCardProps {
    user: User;
}

export const AppUserRowCard = ({ user }: AppUserRowCardProps) => {
    const { t } = useTrans();
    const navigate = useNavigate();

    return (
        <Flex
            h="90px"
            p="1rem"
            gap={6}
            bg="white"
            borderRadius="10px"
            boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
            alignItems={"center"}
            _hover={{ bg: "#f6f6f6" }}
            minWidth="900px"
        >
            <Flex flex="0.8 1 0%">
                <HStack gap="0.5rem">
                    <Avatar
                        size={"lg"}
                        name={user?.fullNameNs}
                        src={useResolveUploadedPath(
                            APPC.BE.Upload.FILETYPE_USER_AVATAR,
                            user?.avatar
                        )}
                    />
                    <Text fontSize="1rem" fontWeight="semibold">
                        {user?.fullName}
                    </Text>
                </HStack>
            </Flex>
            <Flex flex="1.5 1 0%">
                <HStack gap="0.5rem">
                    <AppIcon name="icl-mail" w="1rem" />
                    <Text fontSize="1rem" whiteSpace={"nowrap"}>
                        {user?.email}
                    </Text>
                </HStack>
            </Flex>
            <Flex flex="1 1 0%">
                {user?.phone && (
                    <HStack gap="0.5rem">
                        <AppIcon name="icl-phone" w="1.5rem" />
                        <Text fontSize="1rem" whiteSpace={"nowrap"}>
                            {user?.phone}
                        </Text>
                    </HStack>
                )}
            </Flex>
            <Flex flex="0 0 auto">
                <Button
                    variant={"outline"}
                    size={"sm"}
                    onClick={() =>
                        navigate(
                            cPath("PUBLIC.DETAIL_PAGE", { id: user.id }, false)
                        )
                    }
                >
                    <HStack gap={3}>
                        <AppIcon name="icl-calendar" w="1rem" />
                        <Text fontWeight="normal">
                            {t("com.AppUserRowCard:Button.bookAppointment")}
                        </Text>
                    </HStack>
                </Button>
            </Flex>
        </Flex>
    );
};
