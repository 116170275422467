import { FC } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";

type AppPageHeaderProps = {
    title: string;
    children?: JSX.Element;
};

export const AppPageHeader: FC<AppPageHeaderProps> = ({ title, children }) => {
    return (
        <HStack
            minH={"4.3125rem"}
            px={6}
            borderBottom={"1px solid"}
            borderColor={"borderPrimary"}
            justifyContent={"flex-end"}
            flexWrap={"wrap"}
            gap={0}
        >
            <Box flexGrow={1} mr={6} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                <Text textStyle={"heading4"}>{title}</Text>
            </Box>
            {children}
        </HStack>
    );
};
