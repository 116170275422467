import { useTrans } from "./useTrans";
import { useAuthData } from "../contexts";
import { cPath } from "../config";
import { NavItem } from "../containers/AppNav/AppNav";

export const useNavItems = (type: string = "main"): NavItem[] => {
    const { t } = useTrans();
    const { grant } = useAuthData();

    const userNavItems: NavItem[] = [
        {
            label: t("nav:profile"),
            url: cPath("ADMIN.PROFILE_PAGE"),
            iconName: "icl-address-card",
        },
        {
            label: t("nav:logout"),
            url: cPath("ADMIN.LOGOUT"),
            iconName: "icl-account-logout",
        },
    ];

    const mainNavItems: NavItem[] = [
        {
            label: t("nav:dashboard"),
            url: cPath("ADMIN.DASHBOARD_PAGE"),
            iconName: "icl-developer-board",
            divider: true,
        },
    ];

    if (grant.isSuperAdmin) {
        mainNavItems.push(
            {
                label: t("nav:administration"),
            },
            {
                label: t("nav:user"),
                url: cPath("ADMIN.USER_PAGE"),
                iconName: "icl-people",
            },
            {
                label: t("nav:category"),
                url: cPath("ADMIN.CATEGORY_PAGE"),
                iconName: "icl-note",
            },
            {
                label: t("nav:speciality"),
                url: cPath("ADMIN.SPECIALITY_PAGE"),
                iconName: "icl-movie",
                divider: true,
            }
        );
    } else {
        mainNavItems.push(
            {
                label: t("nav:appointment"),
            },
            {
                label: t("nav:appointmentSetup"),
                url: cPath("ADMIN.APPOINTMENT_SETUP_PAGE"),
                iconName: "icl-settings",
            },
            {
                label: t("nav:appointmentBooking"),
                url: cPath("ADMIN.APPOINTMENT_BOOKING_PAGE"),
                iconName: "icl-calendar",
                divider: true,
            }
        );
    }

    mainNavItems.push({
        label: t("nav:publicArea"),
        url: cPath("PUBLIC.HOME_PAGE"),
        iconName: "icl-browser",
        target: "_blank",
    });

    return type === "user" ? userNavItems : mainNavItems;
};
