import { Canceler } from "axios";
import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse } from "../../services/ApiPlatform/models";

export abstract class UploadApi extends EntityApi {
    protected static POST_COLLECTION = "/api/uploads";

    protected static GET_ITEM = "/api/uploads/{id}";

    public static async upload<R, P>(
        data: P,
        cancelToken?: (c: Canceler) => void,
        setProgress?: (progress: number) => void,
        setRate?: (rate: number) => void
    ): Promise<FinalResponse<R | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.requestPostFormData<R, P>(
            this.POST_COLLECTION,
            data,
            {},
            {
                cancelToken: source.token,
                onUploadProgress: (progressEvent) => {
                    if (setProgress) {
                        const progress = progressEvent?.progress
                            ? progressEvent.progress * 100
                            : 0;

                        setProgress(progress);
                    }
                    if (setRate) {
                        setRate(progressEvent?.rate || 0);
                    }
                },
            }
        )
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((e) => this.handleError(e));
    }
}
