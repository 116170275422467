import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Avatar,
    Box,
    HStack,
    VStack,
    useToast,
    Text,
    Divider,
    Button,
    Flex,
    Tag,
    TagLabel,
    useMediaQuery,
    Input,
    Select,
    Textarea,
    Stack,
} from "@chakra-ui/react";
import {
    Appointment,
    SlotModel,
    User,
    UserApi,
    AppointmentBooking,
    AppointmentBookingApi,
    Speciality,
    SpecialityApi,
} from "../../../apis";
import { AppFormControl, AppIcon, AppLoader } from "../../../components";
import { Calendar } from "react-calendar";
import "../../../assets/scss/Calendar.css";
import dayjs from "dayjs";
import { useResolveUploadedPath, useTrans } from "../../../hooks";
import { APPC, cPath } from "../../../config";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { useSpring, animated } from "@react-spring/web";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";

enum PageStateKey {
    DATE = "DATE",
    TIME_SLOT = "TIME_SLOT",
    USER_FORM = "USER_FORM",
    CONFIRM = "CONFIRM",
}

export const DetailPage = () => {
    // hook
    const { t } = useTrans();
    const navigate = useNavigate();
    const { id } = useParams();
    const toast = useToast();
    const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
    const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
    const animationConfig = {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        config: { duration: 1000 },
    };
    const [animationProps, api] = useSpring(() => animationConfig);

    // state & const
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<User>();
    const [slotData, setSlotData] = useState<SlotModel[] | null>(null);
    const [appointment, setAppointment] = useState<Appointment>();
    const [pageStatus, setPageStatus] = useState<string>(PageStateKey.DATE);
    const [selectedDate, setSelectedDate] = useState<Value>();
    const [selectedTime, setSelectedTime] = useState("");
    const [speciality, setSpeciality] = useState<Speciality[]>([]);

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<AppointmentBooking>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const fetchData = () => {
        setLoading(true);
        UserApi.getUserDetail<User>(`${id}`)
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                    navigate(cPath("PUBLIC.HOME_PAGE"));
                } else if (response !== null) {
                    setData(response);
                    if (response.appointments) {
                        setAppointment(response?.appointments[0]);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchSlotData = () => {
        if (selectedDate) {
            setLoading(true);
            UserApi.getSlot<User>(
                `${id}`,
                dayjs(`${selectedDate}`).format("YYYY-MM-DD")
            )
                .then(({ errorMessage, response }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        if (response?.slots) {
                            setSlotData(response?.slots);
                        }
                    }
                })
                .finally(() => {
                    setPageStatus(PageStateKey.TIME_SLOT);
                    setLoading(false);
                });
        }
    };

    const fetchSpeciality = () => {
        SpecialityApi.getSpecialities<Speciality>().then(
            ({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setSpeciality(response.items);
                }
            }
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchSlotData();
    }, [selectedDate]);

    useEffect(() => {
        if (pageStatus === PageStateKey.USER_FORM) {
            fetchSpeciality();
        }
        api.start(animationConfig);
    }, [pageStatus]);

    const renderLeftCard = (user: User) => {
        return (
            <VStack
                width={isLargerThan1000 ? "360px" : "100%"}
                alignItems={"stretch"}
                gap={"24px"}
                bg={"#ffffff"}
                border={"1px solid #ddd"}
                borderRadius={"10px"}
                p={30}
                boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
            >
                <VStack alignItems={"center"} w={"full"} gap={"16px"}>
                    <Avatar
                        size={"2xl"}
                        name={user?.fullNameNs}
                        src={useResolveUploadedPath(
                            APPC.BE.Upload.FILETYPE_USER_AVATAR,
                            user?.avatar
                        )}
                    />
                    <Box fontSize={"22px"} fontWeight={"semibold"}>
                        {user?.fullName}
                    </Box>
                </VStack>
                <Divider />
                <VStack
                    gap={"12px"}
                    alignItems={"flex-start"}
                    display={"flex"}
                    w={"full"}
                    h={"full"}
                    overflowX={"hidden"}
                >
                    {user?.phone && (
                        <a href={`tel:${user?.phone}`}>
                            <HStack gap={"1rem"} color={"#222222"}>
                                <AppIcon name={"icl-phone"} w="1rem" />
                                <Text fontSize={"14px"}>{user?.phone}</Text>
                            </HStack>
                        </a>
                    )}
                    <a href={`mailto:${user.email}`}>
                        <HStack gap={"1rem"} color={"#222222"}>
                            <AppIcon name={"icl-mail"} w="1rem" />
                            <Text fontSize={"14px"}>{user.email}</Text>
                        </HStack>
                    </a>
                    {user?.bioData && (
                        <Text
                            fontSize={"13px"}
                            lineHeight={"20px"}
                            color={"#787878"}
                            textAlign={"left"}
                            w={"full"}
                        >
                            {user?.bioData}
                        </Text>
                    )}
                </VStack>
            </VStack>
        );
    };

    const dateSelectHandler = (value: Value) => {
        setSelectedDate(value);
    };

    const renderCalendar = () => {
        if (!appointment) {
            return <></>;
        }
        return (
            <Calendar
                className={"detail-calendar"}
                minDate={new Date(appointment?.startTime || "")}
                maxDate={new Date(appointment?.endTime || "")}
                value={selectedDate}
                onChange={dateSelectHandler}
                tileDisabled={({ date }) => {
                    const dt = dayjs(date).format("YYYY-MM-DD");
                    if (
                        appointment.offDays &&
                        appointment?.offDays?.indexOf(dt) > -1
                    ) {
                        return true;
                    }

                    const dow = dayjs(date).day() || 7;
                    return (
                        !appointment?.availabilityDays?.includes(dow) || false
                    );
                }}
            />
        );
    };

    const renderNoSlot = () => (
        <>
            <Flex
                gap={10}
                direction={"column"}
                h={"full"}
                w={"full"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <VStack>
                    <Text
                        textStyle={"heading2"}
                        fontWeight={600}
                        textAlign={"center"}
                    >
                        {t("ppub.Home.DetailPage:text.sorry")}
                    </Text>
                    <Text
                        textStyle={"regularMd"}
                        fontWeight={600}
                        textAlign={"center"}
                    >
                        {t("ppub.Home.DetailPage:text.slotNotAvailable")}
                    </Text>
                </VStack>
                <Button
                    onClick={() => {
                        reset();
                        setSelectedDate(undefined);
                        setSelectedTime("");
                        setPageStatus(PageStateKey.DATE);
                    }}
                >
                    <AppIcon name="icl-calendar" />
                    <Text ml={2}>
                        {t("ppub.Home.DetailPage:button.chooseDiffDate")}
                    </Text>
                </Button>
            </Flex>
        </>
    );

    const renderTimeSlots = () => {
        return (
            <Box
                border={"1px solid #ddd"}
                borderRadius={"10px"}
                p={30}
                style={{ width: "-webkit-fill-available" }}
            >
                <Flex
                    w={"full"}
                    h={"full"}
                    justifyContent={"flex-start"}
                    rowGap={5}
                    columnGap={5}
                    flexWrap={"wrap"}
                >
                    {slotData && slotData.length > 0
                        ? slotData?.map((slot, index) =>
                              slot.isFree ? (
                                  <Tag
                                      w={"94px"}
                                      h={"32px"}
                                      key={index}
                                      size={"lg"}
                                      borderRadius={4}
                                      px={6}
                                      variant={"solid"}
                                      cursor={"pointer"}
                                      color={
                                          slot?.time === selectedTime
                                              ? "#ffffff"
                                              : "#000000"
                                      }
                                      bg={
                                          slot?.time === selectedTime
                                              ? "#000000"
                                              : "#ebebeb"
                                      }
                                      _hover={{ bg: "#cccccc" }}
                                      onClick={() => {
                                          setSelectedTime(slot?.time);
                                          setPageStatus(PageStateKey.USER_FORM);
                                      }}
                                  >
                                      <TagLabel>{slot?.time}</TagLabel>
                                  </Tag>
                              ) : (
                                  <Tag
                                      w={"94px"}
                                      h={"32px"}
                                      key={index}
                                      size={"lg"}
                                      borderRadius={4}
                                      px={6}
                                      variant={"outline"}
                                      cursor={"default"}
                                      color={"#999999"}
                                      bg={"#ffffff"}
                                  >
                                      <TagLabel>{slot?.time}</TagLabel>
                                  </Tag>
                              )
                          )
                        : renderNoSlot()}
                </Flex>
            </Box>
        );
    };

    const onSubmitHandler = (formData: AppointmentBooking) => {
        setLoading(true);
        AppointmentBookingApi.appointmentCheck<
            { isSuccess: boolean; securityCode: string },
            {
                startTime: string;
                appointment: string;
            }
        >({
            startTime: `${dayjs(selectedDate as Date).format(
                "YYYY-MM-DD"
            )} ${selectedTime}:00`,
            appointment: `/api/appointments/${appointment?.id}`,
        })
            .then(({ response, errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    formData.startTime = `${dayjs(selectedDate as Date).format(
                        "YYYY-MM-DD"
                    )} ${selectedTime}:00`;
                    formData.appointment = `/api/appointments/${appointment?.id}`;
                    formData.securityCode = response?.securityCode;
                    if (!formData?.userSpeciality) {
                        formData.userSpeciality = null;
                    }
                    AppointmentBookingApi.postCollection<
                        AppointmentBooking,
                        AppointmentBooking
                    >(formData).then(({ response, errorMessage }) => {
                        if (errorMessage) {
                            toast({
                                title: errorMessage,
                                status: "error",
                            });
                        } else if (response !== null) {
                            setPageStatus(PageStateKey.CONFIRM);
                        }
                    });
                }
            })
            .finally(() => setLoading(false));
    };

    const renderUserForm = () => {
        return (
            <Box border={"1px solid #ddd"} borderRadius={"10px"} p={30}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    {loading && <AppLoader />}
                    <VStack gap={5} alignItems={"stretch"}>
                        <Stack
                            gap={4}
                            w={"full"}
                            direction={{
                                base: "column",
                                md: "row",
                            }}
                        >
                            <AppFormControl
                                label={t(
                                    "ent.AppointmentBooking:userFirstName.label"
                                )}
                                isInvalid={!!errors?.userFirstName}
                                message={errors?.userFirstName?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.AppointmentBooking:userFirstName.placeholder"
                                    )}
                                    {...register("userFirstName")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t(
                                    "ent.AppointmentBooking:userLastName.label"
                                )}
                                isInvalid={!!errors?.userLastName}
                                message={errors?.userLastName?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.AppointmentBooking:userLastName.placeholder"
                                    )}
                                    {...register("userLastName")}
                                />
                            </AppFormControl>
                        </Stack>
                        <Stack
                            gap={4}
                            w={"full"}
                            direction={{
                                base: "column",
                                md: "row",
                            }}
                        >
                            <AppFormControl
                                label={t(
                                    "ent.AppointmentBooking:userEmail.label"
                                )}
                                isInvalid={!!errors?.userEmail}
                                message={errors?.userEmail?.message}
                            >
                                <Input
                                    type="email"
                                    placeholder={t(
                                        "ent.AppointmentBooking:userEmail.placeholder"
                                    )}
                                    {...register("userEmail")}
                                />
                            </AppFormControl>
                            <AppFormControl
                                label={t(
                                    "ent.AppointmentBooking:userPhone.label"
                                )}
                                isInvalid={!!errors?.userPhone}
                                message={errors?.userPhone?.message}
                            >
                                <Input
                                    type="text"
                                    placeholder={t(
                                        "ent.AppointmentBooking:userPhone.placeholder"
                                    )}
                                    {...register("userPhone")}
                                />
                            </AppFormControl>
                        </Stack>
                        <AppFormControl
                            label={t(
                                "ent.AppointmentBooking:userSpeciality.label"
                            )}
                        >
                            <Select
                                placeholder={t(
                                    "ent.AppointmentBooking:userSpeciality.placeholder"
                                )}
                                {...register("userSpeciality")}
                            >
                                {speciality?.map((s) => (
                                    <option key={s.id} value={s["@id"]}>
                                        {s.name}
                                    </option>
                                ))}
                            </Select>
                        </AppFormControl>
                        <AppFormControl
                            label={t("ent.AppointmentBooking:comment.label")}
                        >
                            <Textarea
                                placeholder={t(
                                    "ent.AppointmentBooking:comment.placeholder"
                                )}
                                {...register("comment")}
                            />
                        </AppFormControl>
                    </VStack>
                    <Box textAlign={"center"} mt={6}>
                        <Button type="submit">
                            <AppIcon name="icl-check" />
                            <Text ml={2}>
                                {t(
                                    "ppub.Home.DetailPage:button.bookAppointment"
                                )}
                            </Text>
                        </Button>
                    </Box>
                </form>
            </Box>
        );
    };

    const renderSelectedDetails = () => {
        return (
            <Flex
                border={"1px solid #ddd"}
                borderRadius={"10px"}
                p={6}
                gap={6}
                direction={isLargerThan600 ? "row" : "column"}
            >
                {appointment?.timezone && (
                    <Flex direction={"column"} flex={1} alignItems={"center"}>
                        <HStack mb={1}>
                            <AppIcon name="icl-globe-alt" />
                            <Text display="inline-block" textStyle={"boldMd"}>
                                {t("ppub.Home.DetailPage:text.timezone")}
                            </Text>
                        </HStack>
                        <Text whiteSpace={"nowrap"}>
                            {APPC.BE.Timezone[`${appointment?.timezone}`]}
                        </Text>
                    </Flex>
                )}

                {selectedDate && (
                    <Flex direction={"column"} flex={1} alignItems={"center"}>
                        <HStack mb={1}>
                            <AppIcon name="icl-calendar" />
                            <Text display="inline-block" textStyle={"boldMd"}>
                                {t("ppub.Home.DetailPage:text.date")}
                            </Text>
                        </HStack>
                        <Text>
                            {dayjs(selectedDate as Date).format("DD/MM/YYYY")}
                        </Text>
                    </Flex>
                )}

                {selectedTime && (
                    <Flex direction={"column"} flex={1} alignItems={"center"}>
                        <HStack mb={1}>
                            <AppIcon name="icl-clock" />
                            <Text display="inline-block" textStyle={"boldMd"}>
                                {t("ppub.Home.DetailPage:text.time")}
                            </Text>
                        </HStack>
                        <Text>{selectedTime}</Text>
                    </Flex>
                )}
            </Flex>
        );
    };

    const renderConfirmPage = () => {
        return (
            <Flex
                border={"1px solid #ddd"}
                borderRadius={"10px"}
                p={6}
                gap={10}
                direction={"column"}
                h={"full"}
                w={"full"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <AppIcon name="icl-thumb1-up" w="120px" color="#999999" />
                <VStack>
                    <Text
                        textStyle={"heading2"}
                        fontWeight={600}
                        textAlign={"center"}
                    >
                        {t("ppub.Home.DetailPage:text.thankyou")}
                    </Text>
                    <Text
                        textStyle={"regularMd"}
                        fontWeight={600}
                        textAlign={"center"}
                    >
                        {appointment?.isAppointmentConfirmManual === true
                            ? t(
                                  "ppub.Home.DetailPage:message.appointmentNotification"
                              )
                            : t(
                                  "ppub.Home.DetailPage:message.appointmentConfirm"
                              )}
                    </Text>
                </VStack>
                <Button
                    onClick={() => {
                        reset();
                        setSelectedDate(undefined);
                        setSelectedTime("");
                        setPageStatus(PageStateKey.DATE);
                    }}
                >
                    <AppIcon name="icl-calendar" />
                    <Text ml={2}>
                        {t("ppub.Home.DetailPage:button.anotherAppointment")}
                    </Text>
                </Button>
            </Flex>
        );
    };

    const pageState = {
        [PageStateKey.DATE]: {
            label: t("ppub.Home.DetailPage:text.pageState.date.label"),
            renderFn: renderCalendar,
            prevState: null,
        },
        [PageStateKey.TIME_SLOT]: {
            label: t("ppub.Home.DetailPage:text.pageState.timeSlot.label"),
            renderFn: renderTimeSlots,
            prevState: [PageStateKey.DATE],
        },
        [PageStateKey.USER_FORM]: {
            label: t("ppub.Home.DetailPage:text.pageState.userForm.label"),
            renderFn: renderUserForm,
            prevState: [PageStateKey.TIME_SLOT],
        },
        [PageStateKey.CONFIRM]: {
            label: t("ppub.Home.DetailPage:text.pageState.confirm.label"),
            renderFn: renderConfirmPage,
            prevState: null,
        },
    };

    return (
        <>
            <Flex
                gap={8}
                flexWrap={isLargerThan1000 ? "nowrap" : "wrap-reverse"}
                minHeight="795px"
            >
                <Flex
                    minW={isLargerThan1000 ? "360px" : "100%"}
                    flexBasis={{
                        base: "full",
                        sm: "full",
                        md: "360px",
                        lg: "360px",
                    }}
                >
                    {data && renderLeftCard(data)}
                </Flex>
                <animated.div
                    style={{
                        ...animationProps,
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        padding: 30,
                        boxShadow:
                            "0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)",
                    }}
                >
                    <HStack>
                        {pageState[pageStatus].prevState && (
                            <Button
                                position={"absolute"}
                                variant={"outline"}
                                size={"sm"}
                                onClick={() => {
                                    if (pageState[pageStatus].prevState) {
                                        setPageStatus(
                                            pageState[pageStatus].prevState
                                        );
                                        reset();
                                    }
                                }}
                            >
                                <HStack gap={3}>
                                    <AppIcon
                                        name="icl-arrow-thick-left"
                                        w="1rem"
                                    />
                                    <Text
                                        as="span"
                                        fontWeight="normal"
                                        display={{
                                            base: "none",
                                            sm: "none",
                                            md: "block",
                                        }}
                                    >
                                        {t("cmn:button.back")}
                                    </Text>
                                </HStack>
                            </Button>
                        )}
                        <Text
                            textStyle={"mediumLg"}
                            textAlign={"center"}
                            w={"full"}
                            px={10}
                        >
                            {pageState[pageStatus].label}
                        </Text>
                    </HStack>
                    <Divider my={6} />
                    {renderSelectedDetails()}
                    <Divider my={6} />
                    {loading ? <AppLoader /> : pageState[pageStatus].renderFn()}
                </animated.div>
            </Flex>
        </>
    );
};
