import { default as i18next } from "i18next";
import { useTranslation } from "react-i18next";
import { LSC } from "../config";

type TransType = {
    t: (
        translationKey: string,
        values?: { [key: string]: string | number }
    ) => string;
    changeLocale: (locale: string) => void;
};

export const useTrans = (): TransType => {
    const { t } = useTranslation();

    const translate = (
        translationKey: string,
        values: { [key: string]: string | number } = {}
    ) =>
        localStorage.getItem(LSC.APP_TRANS_DEBUG_KEY) === "true"
            ? translationKey
            : t(translationKey, values);

    const changeLocale = (locale: string) => i18next.changeLanguage(locale);

    return { t: translate, changeLocale };
};
