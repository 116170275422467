import { useState } from "react";
import { ActionType, ITableInstance, useTable } from "ka-table";
import { APPC } from "../config";

export type AppTableReturnType = {
    table: ITableInstance;
    pageIndex: number;
    pagesCount: number;
    setPagesCount: (v: number) => void;
    pageSize: number;
    sortColumn: string;
    sortOrder: string;
};

export function useAppTable(
    defaultSortColumn: string,
    defaultSortOrder: string = "asc"
): AppTableReturnType {
    const [pageIndex, setPageIndex] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);
    const [pageSize, setPageSize] = useState(APPC.PAGE_SIZE);
    const [sortColumn, setSortColumn] = useState(defaultSortColumn);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);

    const table = useTable({
        onDispatch: async (action) => {
            if (action.type === ActionType.UpdatePageIndex) {
                setPageIndex(action.pageIndex);
            } else if (action.type === ActionType.UpdatePageSize) {
                setPageSize(action.pageSize);
            } else if (action.type === ActionType.UpdateSortDirection) {
                if (action.columnKey === sortColumn) {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                } else {
                    setSortColumn(action.columnKey);
                    setSortOrder("asc");
                }
            }
        },
    });

    return {
        table,
        pageIndex,
        pagesCount,
        setPagesCount,
        pageSize,
        sortColumn,
        sortOrder,
    };
}
