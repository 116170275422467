import { Button, Center, Flex, Spacer, Text } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

interface AppStatasticCardProps {
    text1?: string;
    text2?: string;
    iconName?: string;
    onClick?: () => void;
}

export const AppStatasticCard = ({
    text1 = "",
    text2 = "",
    iconName = "",
    onClick,
}: AppStatasticCardProps) => {
    return (
        <Flex
            h={"143px"}
            direction={"column"}
            p={"1.25rem"}
            border={"1px solid #E4E4E4"}
            borderRadius={"4px"}
            bg={"white"}
            justifyContent={"space-between"}
        >
            <Flex alignItems={"center"}>
                {iconName && (
                    <Center w={"32px"} h={"32px"} bg={"#F2F2F2"}>
                        <AppIcon name={iconName} w="1rem" />
                    </Center>
                )}
                <Spacer />
                {onClick && (
                    <Button
                        className="btn-icon-sm"
                        size="sm"
                        variant={"transparent"}
                        onClick={onClick}
                    >
                        <AppIcon name="icl-chevron-right-alt" w="1rem" />
                    </Button>
                )}
            </Flex>
            <Flex direction={"column"} gap={1}>
                <Text
                    textStyle={"regularSm"}
                    fontWeight={500}
                    color={"#727272"}
                >
                    {text1}
                </Text>
                <Text textStyle={"mediumXl"} fontWeight={500} color={"#000000"}>
                    {text2}
                </Text>
            </Flex>
        </Flex>
    );
};
