import * as yup from "yup";

export const schema = (t: (val: string) => string) => {
    const validation = {
        firstName: yup.string().required(t("cmn:val.required")),
        lastName: yup.string().required(t("cmn:val.required")),
        phone: yup.string().required(t("cmn:val.required")),
    };
    return yup.object().shape({ ...validation });
};
