import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import {
    createMultiStyleConfigHelpers,
    defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleDialog = defineStyle({
    bg: "drawerBg",
    border: "1px solid var(--chakra-colors-drawerBorder)",
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    boxShadow: "0px 4px 36px 0px #96969629",
});

const baseStyleOverlay = {
    bg: "drawerBgOverlay",
};

const baseStyleHeader = defineStyle({
    fontFamily: "Inter SemiBold, sans-serif",
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.3612rem",
    borderBottom: "1px solid var(--chakra-colors-drawerBorder)",
    padding: 6,
});

const baseStyleBody = defineStyle({
    fontFamily: "Inter Regular, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.3125rem",
    color: "drawerText",
    padding: 6,
    scrollbarWidth: "thin",
});

const baseStyleFooter = defineStyle({
    borderTop: "1px solid var(--chakra-colors-drawerBorder)",
    padding: 6,
});

const baseStyle = definePartsStyle({
    header: baseStyleHeader,
    body: baseStyleBody,
    footer: baseStyleFooter,
    dialog: baseStyleDialog,
    overlay: baseStyleOverlay,
});

export const Drawer = defineMultiStyleConfig({
    baseStyle,
});
