import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts";
import { AuthApi, User } from "../apis";
import { ENUM, LSC, cPath } from "../config";
import { useToast } from "@chakra-ui/react";

type AuthActionType = {
    isAuthenticated: boolean;
    setAuthTokenAction: (token: string) => void;
    setUserAction: (user: User) => void;
    loginAction: (payload: User) => void;
    logoutAction: () => void;
    reloadUserAction: () => Promise<void>;
};

export function useAuthAction(): AuthActionType {
    // hooks
    const navigate = useNavigate();
    const toast = useToast();

    // contexts
    const { state, dispatch } = useContext(AuthContext);

    // state
    const { isAuthenticated } = state;

    const setAuthTokenAction = (token: string) => {
        localStorage.setItem(LSC.APP_TOKEN_KEY, token);
    };

    const setUserAction = (user: User) => {
        localStorage.setItem(LSC.APP_USER_KEY, JSON.stringify(user));
    };

    const loginAction = (payload: User) => {
        setUserAction(payload);

        dispatch({
            type: ENUM.AuthContextAction.LOGIN_SUCCESS,
            payload,
        });
    };

    const logoutAction = () => {
        dispatch({ type: ENUM.AuthContextAction.LOGOUT });
        navigate(cPath("PUBLIC.HOME_PAGE"));
    };

    const reloadUserAction = async () =>
        AuthApi.me<User>().then(({ response, errorMessage }) => {
            if (errorMessage) {
                toast({ title: errorMessage, status: "error" });
            } else if (response !== null) {
                setUserAction(response);

                dispatch({
                    type: ENUM.AuthContextAction.RELOAD_USER,
                    payload: response,
                });
            }
        });

    return {
        isAuthenticated,
        setAuthTokenAction,
        setUserAction,
        loginAction,
        logoutAction,
        reloadUserAction,
    };
}
