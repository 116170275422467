import { FC, useEffect, useRef, useState } from "react";
import {
    FormContainerStateReturnType,
    useAppTable,
    useAppUtil,
    useTrans,
} from "../../../hooks";
import { Canceler } from "axios";
import {
    useToast,
    Text,
    HStack,
    Button,
    useDisclosure,
    VStack,
    Badge,
    Tabs,
    TabList,
    Tab,
    Box,
} from "@chakra-ui/react";
import {
    AppointmentBooking as ListEntity,
    AppointmentBookingApi as ListEntityApi,
} from "../../../apis";
import { DataType, SortingMode, Table as KaTable } from "ka-table";
import { ICellTextProps } from "ka-table/props";
import { AppAlert, AppIcon, KaPageSizeSelector } from "../../../components";
import { APPC } from "../../../config";
import { Column } from "ka-table/models";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const AppointmentBookingList: FC<{
    formContainerState: FormContainerStateReturnType;
    search?: string;
}> = ({ formContainerState, search }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const {
        isOpen: isOpenAppointmentAction,
        onOpen: onOpenAppointmentAction,
        onClose: onCloseAppointmentAction,
    } = useDisclosure();
    const { statusVariant } = useAppUtil();

    // state & const
    const [loading, setLoading] = useState(true);
    const [selectId, setSelectId] = useState(0);
    const [status, setStatus] = useState("");
    const [list, setList] = useState<ListEntity[]>([]);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const [tabIndex, setTabIndex] = useState(0);
    dayjs.extend(utc);

    // table
    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("startTime");

    const columns: Column[] = [
        {
            key: "startTime",
            title: t("ent.AppointmentBooking:startTime.label"),
            dataType: DataType.String,
            width: 200,
        },
        {
            key: "userFullName",
            title: t("ent.AppointmentBooking:userFullName.label"),
            dataType: DataType.String,
        },
        {
            key: "userEmail",
            title: t("ent.AppointmentBooking:userEmail.label"),
            dataType: DataType.String,
        },
        {
            key: "userPhone",
            title: t("ent.AppointmentBooking:userPhone.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "userSpeciality?.name",
            title: t("ent.AppointmentBooking:userSpeciality.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "status",
            title: t("ent.AppointmentBooking:status.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "action",
            title: t("cmn:label.listAction"),
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 230,
            isSortable: false,
        },
    ];

    const appointmentActionHandler = async () => {
        if (selectId < 1) {
            return;
        }
        setLoading(true);
        ListEntityApi.changeStatus(selectId, { status })
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchListData = () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["appointment_booking_search"] = search;
        }
        params["appointment_booking_is_archive"] = tabIndex ? true : false;

        ListEntityApi.getCollection<ListEntity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
        tabIndex,
    ]);

    const renderAction = (props) => (
        <>
            {props?.rowData?.status ===
                APPC.BE.AppointmentBooking.BOOKING_STATUS_CREATED &&
                props?.rowData?.isAppointmentConfirmManual && (
                    <>
                        <Button
                            variant={"outline"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => {
                                setSelectId(props?.rowData?.id);
                                setStatus(
                                    APPC.BE.AppointmentBooking
                                        .BOOKING_STATUS_ACCEPTED
                                );
                                onOpenAppointmentAction();
                            }}
                        >
                            {t("padm.AppointmentBookingPage:button.accept")}
                        </Button>
                        <Button
                            variant={"destructive"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => {
                                setSelectId(props?.rowData?.id);
                                setStatus(
                                    APPC.BE.AppointmentBooking
                                        .BOOKING_STATUS_REJECTED
                                );
                                onOpenAppointmentAction();
                            }}
                        >
                            {t("padm.AppointmentBookingPage:button.reject")}
                        </Button>
                    </>
                )}
            <Button
                variant={"outline"}
                size={"sm"}
                className="btn-icon-sm"
                onClick={() => formContainerState.open(props?.rowData?.id)}
            >
                <AppIcon name="icl-eye" w="1rem" />
            </Button>
        </>
    );

    const handleTabsChange = (index) => {
        setTabIndex(index);
    };

    return (
        <Box w={"full"}>
            <Tabs mb="6" index={tabIndex} onChange={handleTabsChange}>
                <TabList>
                    <Tab>
                        {t("padm.AppointmentBookingPage:text.tab.upcoming")}
                    </Tab>
                    <Tab>{t("padm.AppointmentBookingPage:text.tab.past")}</Tab>
                </TabList>
            </Tabs>
            <KaTable
                table={table}
                columns={columns}
                data={list || []}
                loading={{
                    enabled: loading,
                }}
                paging={{
                    enabled: true,
                    pageSizes: APPC.PAGE_SIZES,
                    pageSize,
                    pageIndex,
                    pagesCount,
                }}
                rowKeyField={"id"}
                sortingMode={SortingMode.SingleRemote}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "startTime":
                                    return (
                                        <Text
                                            textStyle={"mediumSm"}
                                            color={"black"}
                                        >
                                            {dayjs(props?.rowData?.startTime)
                                                .utc()
                                                .format("DD-MM-YYYY HH:mm:ss")}
                                        </Text>
                                    );
                                case "userSpeciality?.name":
                                    return props?.rowData?.userSpeciality
                                        ? `${props?.rowData?.userSpeciality?.name}`
                                        : "";
                                case "status":
                                    return (
                                        <Badge
                                            variant={statusVariant(
                                                props?.rowData?.status
                                            )}
                                        >
                                            {props?.rowData?.status}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            {renderAction(props)}
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />
            {isOpenAppointmentAction && selectId && (
                <AppAlert
                    title={t(
                        `padm.AppointmentBookingPage:alert.${status.toLowerCase()}.title`
                    )}
                    isOpen={isOpenAppointmentAction}
                    onSuccess={appointmentActionHandler}
                    onCancel={onCloseAppointmentAction}
                    successLable={t("cmn:alert.delete.button.success")}
                    cancelLable={t("cmn:alert.delete.button.cancel")}
                >
                    <VStack py={6}>
                        <AppIcon
                            name={
                                status ===
                                APPC.BE.AppointmentBooking
                                    .BOOKING_STATUS_ACCEPTED
                                    ? "icl-check"
                                    : "icl-x-circle"
                            }
                            w="2.5rem"
                            color={
                                status ===
                                APPC.BE.AppointmentBooking
                                    .BOOKING_STATUS_ACCEPTED
                                    ? "green"
                                    : "red"
                            }
                        />
                        <Text textStyle={"boldMd"}>
                            {t(
                                `padm.AppointmentBookingPage:alert.${status.toLowerCase()}.message`
                            )}
                        </Text>
                    </VStack>
                </AppAlert>
            )}
        </Box>
    );
};
