const masterColors = {
    appPrimary: "#000000",
    appSecondary: "#FFFFFF",
    appDisabledText: "#D8D8D8",
    appDisabledBg: "#F9F9F9",
    appDisabledBorder: "",
    appBorder: "#AFAFAF",
    appFocusBorder: "",
    appPlaceholder: "#AFAFAF",
    appDanger: "#AF4B4B",
};

// const masterSizes = {
//     appBorderRadius: "0.25rem",
// };

const colors = {
    contentPrimary: "#000000",
    contentSecondary: "#727272",
    contentTertiary: "#AFAFAF",
    contentDisable: "#D8D8D8",
    contentInformative: "#4976F4",
    contentPositive: "#589E67",
    contentError: "#AF4B4B",

    contentPrimaryLight: "#FFFFFF",
    contentSecondaryLight: "#F9F9F9",
    contentTertiaryLight: "#F2F2F2",
    contentDisableLight: "#D8D8D8",
    contentInformativeLight: "#4976F4",
    contentPositiveLight: "#589E67",
    contentErrorLight: "#AF4B4B",

    borderPrimary: "#E4E4E4",
    borderSecondary: "#AFAFAF",
    borderTertiary: "#000000",
    borderInformative: "#BCD8C2",
    borderPositive: "#BCD8C2",
    borderError: "#DFB7B7",
    borderWarning: "#EDD2B1",

    backgroundPrimary: "#FFFFFF",
    backgroundSecondary: "#F9F9F9",
    backgroundTertiary: "#F2F2F2",
    backgroundBlue: "#EDF2FE",
    backgroundGreen: "#EEF5F0",
    backgroundRed: "#F7EDED",
    backgroundOrange: "#FBF4EC",
    backgroundPurple: "#F4EDF7",
    backgroundYello: "#F7F7E8",

    actionBasePrimary: "#000000",
    actionHoverPrimary: "#727272",
    actionActivePrimary: "#4B4B4B",
    actionSelectedPrimary: "#4B4B4B",
    actionDisabledPrimary: "#F9F9F9",

    actionBaseSecondary: "#FFFFFF",
    actionBase2Secondary: "#F2F2F2",
    actionHoverSecondary: "#E8E8E8",
    actionActiveSecondary: "#E4E4E4",
    actionSelectedSecondary: "#F2F2F2",
    actionDisabledSecondary: "#F9F9F9",

    actionBaseOutline: "#E4E4E4",
    actionHoverOutline: "#000000",
    actionActiveOutline: "#727272",
    actionSelectedOutline: "#AFAFAF",
    actionDisabledOutline: "#F2F2F2",

    actionBaseDestructive: "#BF6F6F",
    actionHoverDestructive: "#AF4B4B",
    actionActiveDestructive: "#8C3C3C",
    actionSelectedDestructive: "#AF4B4B",
    actionDisabledDestructive: "#EFDBDB",

    interactionBasePrimary: "#000000",
    interactionHoverPrimary: "#727272",
    interactionActivePrimary: "#4B4B4B",
    interactionSelectedPrimary: "#4B4B4B",
    interactionDisabledPrimary: "#F9F9F9",

    interactionBaseSecondary: "#FFFFFF",
    interactionHoverSecondary: "#F2F2F2",
    interactionActiveSecondary: "#E4E4E4",
    interactionSelectedSecondary: "#F2F2F2",
    interactionDisabledSecondary: "#F9F9F9",

    interactionBaseOutline: "#D8D8D8",
    interactionHoverOutline: "#727272",
    interactionActiveOutline: "#000000",
    interactionSelectedOutline: "#AFAFAF",
    interactionDisabledOutline: "#E8E8E8",

    interactionBaseRed: "#AF4B4B",
    interactionHoverRed: "#8C3C3C",
    interactionActiveRed: "#692D2D",
    interactionSelectedRed: "#8C3C3C",
    interactionDisabledRed: "#EFDBDB",

    shadowPrimary: "rgba(224, 92, 193, 0.6)",

    ...masterColors,
    // ...masterSizes,

    // BUTTON

    // buttonShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    // buttonBorderRadius: masterSizes.appBorderRadius,
    primaryButtonBg: masterColors.appPrimary,
    primaryButtonText: masterColors.appSecondary,
    primaryButtonHoverBg: "#727272",
    primaryButtonHoverText: masterColors.appSecondary,
    primaryButtonActiveBg: "#4B4B4B",
    primaryButtonActiveText: masterColors.appSecondary,
    primaryButtonDisabledBg: masterColors.appDisabledBg,
    primaryButtonDisabledText: masterColors.appDisabledText,
    primaryButtonDisabledHoverBg: masterColors.appDisabledBg,
    primaryButtonDisabledHoverText: masterColors.appDisabledText,
    secondaryButtonBg: "#F2F2F2",
    secondaryButtonText: masterColors.appPrimary,
    secondaryButtonHoverBg: "#E8E8E8",
    secondaryButtonHoverText: masterColors.appPrimary,
    secondaryButtonActiveBg: "#E4E4E4",
    secondaryButtonActiveText: masterColors.appPrimary,
    secondaryButtonDisabledBg: masterColors.appDisabledBg,
    secondaryButtonDisabledText: masterColors.appDisabledText,
    secondaryButtonDisabledHoverBg: masterColors.appDisabledBg,
    secondaryButtonDisabledHoverText: masterColors.appDisabledText,
    outlineButtonBg: masterColors.appSecondary,
    outlineButtonText: masterColors.appPrimary,
    outlineButtonBorder: masterColors.appPrimary,
    outlineButtonHoverBg: "#E8E8E8",
    outlineButtonHoverText: masterColors.appPrimary,
    outlineButtonHoverBorder: masterColors.appPrimary,
    outlineButtonActiveBg: "#727272",
    outlineButtonActiveText: masterColors.appSecondary,
    outlineButtonDisabledBg: masterColors.appSecondary,
    outlineButtonDisabledText: masterColors.appDisabledText,
    outlineButtonDisabledBorder: "#F2F2F2",
    outlineButtonDisabledHoverBg: masterColors.appSecondary,
    outlineButtonDisabledHoverText: masterColors.appDisabledText,
    outlineButtonDisabledHoverBorder: "#F2F2F2",
    destructiveButtonBg: masterColors.appSecondary,
    destructiveButtonText: "#BF6F6F",
    destructiveButtonBorder: "#BF6F6F",
    destructiveButtonHoverBg: "#F7EDED",
    destructiveButtonHoverText: masterColors.appDanger,
    destructiveButtonHoverBorder: masterColors.appDanger,
    destructiveButtonActiveBg: "#8C3C3C",
    destructiveButtonActiveText: "#BF6F6F",
    destructiveButtonDisabledBg: masterColors.appSecondary,
    destructiveButtonDisabledText: "#EFDBDB",
    destructiveButtonDisabledBorder: "#F7EDED",
    destructiveButtonDisabledHoverBg: masterColors.appSecondary,
    destructiveButtonDisabledHoverText: "#EFDBDB",
    destructiveButtonDisabledHoverBorder: "#F7EDED",
    transparentButtonBg: "transparent",
    transparentButtonText: "#727272",
    transparentButtonHoverBg: "#F2F2F2",
    transparentButtonHoverText: "#727272",
    transparentButtonActiveBg: "#F2F2F2",
    transparentButtonActiveText: masterColors.appPrimary,
    transparentButtonDisabledBg: masterColors.appDisabledBg,
    transparentButtonDisabledText: masterColors.appDisabledText,
    transparentButtonDisabledHoverBg: masterColors.appDisabledBg,
    transparentButtonDisabledHoverText: masterColors.appDisabledText,
    tabButtonBg: masterColors.appSecondary,
    tabButtonText: "#727272",
    tabButtonActiveText: masterColors.appPrimary,
    tabButtonActiveBorder: masterColors.appPrimary,
    tabButtonDisabledText: masterColors.appDisabledText,
    tabButtonDisabledHoverText: masterColors.appDisabledText,

    // BADGE

    greenBadgeBg: "#EEF5F0",
    greenBadgeText: "#589e67",
    blueBadgeBg: "#EDF2FE",
    blueBadgeText: "#4976F4",
    redBadgeBg: "#F7EDED",
    redBadgeText: masterColors.appDanger,
    primaryBadgeBg: masterColors.appPrimary,
    primaryBadgeText: masterColors.appSecondary,
    secondaryBadgeBg: "#F2F2F2",
    secondaryBadgeText: masterColors.appPrimary,

    // DRAWER

    drawerBg: masterColors.appSecondary,
    drawerBgOverlay: "#00000066",
    drawerText: "#727272",
    drawerBorder: "#E4E4E4",

    // MODAL

    modalBg: masterColors.appSecondary,
    modalBgOverlay: "#00000066",
    modalText: "#727272",
    modalBorder: "#E4E4E4",

    // TAB

    tabText: "#727272",
    tabSelectedText: masterColors.appPrimary,
    tabSelectedBorder: masterColors.appPrimary,

    // SWITCH

    primarySwitchTrackBorder: masterColors.appBorder,
    primarySwitchTrackBg: masterColors.appSecondary,
    primarySwitchTrackCheckedBg: masterColors.appPrimary,
    primarySwitchTrackDisabledBg: masterColors.appDisabledBg,
    primarySwitchTrackDisabledBorder: masterColors.appDisabledBorder,
    primarySwitchThumbBg: masterColors.appPrimary,
    primarySwitchThumbCheckedBg: masterColors.appSecondary,
    primarySwitchThumbDisabledBg: masterColors.appPrimary,

    // TEXTAREA

    // primaryTextAreaBorderRadius: masterSizes.appBorderRadius,
    primaryTextAreaBg: masterColors.appSecondary,
    primaryTextAreaBorder: masterColors.appBorder,
    primaryTextAreaText: masterColors.appPrimary,
    primaryTextAreaPlaceholder: masterColors.appPlaceholder,
    primaryTextAreaHoverBorder: "#727272",
    primaryTextAreaFocusBorder: masterColors.appPrimary,
    primaryTextAreaFocusShadow: masterColors.appPrimary,
    primaryTextAreaDisabledBg: masterColors.appDisabledBg,
    primaryTextAreaDisabledBorder: masterColors.appDisabledBorder,
    primaryTextAreaDisabledText: masterColors.appDisabledText,
    primaryTextAreaDisabledHoverBg: masterColors.appDisabledBg,
    primaryTextAreaDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryTextAreaDisabledHoverText: masterColors.appDisabledText,

    // INPUT

    primaryInputFieldBg: masterColors.appSecondary,
    primaryInputFieldBorder: masterColors.appBorder,
    primaryInputFieldText: masterColors.appPrimary,
    primaryInputFieldDisabledBg: masterColors.appDisabledBg,
    primaryInputFieldDisabledBorder: masterColors.appDisabledBorder,
    primaryInputFieldDisabledText: masterColors.appDisabledText,
    primaryInputFieldDisabledHoverBg: masterColors.appDisabledBg,
    primaryInputFieldDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryInputFieldDisabledHoverText: masterColors.appDisabledText,
    primaryInputFieldPlaceholder: masterColors.appPlaceholder,
    primaryInputFieldHoverBorder: "#727272",
    primaryInputFieldInvalidBorder: masterColors.appDanger,
    primaryInputFieldInvalidShadow: masterColors.appDanger,
    primaryInputFieldFocusBorder: "#4B4B4B",
    primaryInputFieldFocusShadow: "#4B4B4B",
    primaryInputAddonBg: masterColors.appSecondary,
    primaryInputAddonBorder: masterColors.appBorder,
    primaryInputAddonText: "#AFAFAF",
    primaryInputAddonDisabledBg: masterColors.appDisabledBg,
    primaryInputAddonDisabledBorder: masterColors.appDisabledBorder,
    primaryInputAddonDisabledText: masterColors.appDisabledText,
    primaryInputAddonDisabledHoverBg: masterColors.appDisabledBg,
    primaryInputAddonDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryInputAddonDisabledHoverText: masterColors.appDisabledText,
    primaryInputElementText: "#AFAFAF",
    primaryInputElementDisabledBg: masterColors.appDisabledBg,
    primaryInputElementDisabledBorder: masterColors.appDisabledBorder,
    primaryInputElementDisabledText: masterColors.appDisabledText,
    primaryInputElementDisabledHoverBg: masterColors.appDisabledBg,
    primaryInputElementDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryInputElementDisabledHoverText: masterColors.appDisabledText,

    // NUMBER INPUT

    primaryNumberInputFieldBg: masterColors.appSecondary,
    primaryNumberInputFieldBorder: masterColors.appBorder,
    primaryNumberInputFieldText: masterColors.appPrimary,
    primaryNumberInputFieldDisabledBg: masterColors.appDisabledBg,
    primaryNumberInputFieldDisabledBorder: masterColors.appDisabledBorder,
    primaryNumberInputFieldDisabledText: masterColors.appDisabledText,
    primaryNumberInputFieldDisabledHoverBg: masterColors.appDisabledBg,
    primaryNumberInputFieldDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryNumberInputFieldDisabledHoverText: masterColors.appDisabledText,
    primaryNumberInputFieldPlaceholder: masterColors.appPlaceholder,
    primaryNumberInputFieldHoverBorder: "#727272",
    primaryNumberInputFieldInvalidBorder: masterColors.appDanger,
    primaryNumberInputFieldInvalidShadow: masterColors.appDanger,
    primaryNumberInputFieldFocusBorder: "#4B4B4B",
    primaryNumberInputFieldFocusShadow: "#4B4B4B",

    // SELECT

    primarySelectFieldBg: masterColors.appSecondary,
    primarySelectFieldBorder: masterColors.appBorder,
    primarySelectFieldText: masterColors.appPrimary,
    primarySelectFieldDisabledBg: masterColors.appDisabledBg,
    primarySelectFieldDisabledBorder: masterColors.appDisabledBorder,
    primarySelectFieldDisabledText: masterColors.appDisabledText,
    primarySelectFieldDisabledHoverBg: masterColors.appDisabledBg,
    primarySelectFieldDisabledHoverBorder: masterColors.appDisabledBorder,
    primarySelectFieldDisabledHoverText: masterColors.appDisabledText,
    primarySelectFieldPlaceholder: masterColors.appPlaceholder,
    primarySelectFieldHoverBorder: "#727272",
    primarySelectFieldFocusBorder: masterColors.appPrimary,
    primarySelectFieldFocusShadow: masterColors.appPrimary,
    primarySelectIcon: masterColors.appPrimary,

    // CHECKBOX

    // primaryCheckboxControlBorderRadius: masterSizes.appBorderRadius,
    primaryCheckboxControlBorder: masterColors.appBorder,
    primaryCheckboxControlBg: masterColors.appSecondary,
    primaryCheckboxControlHoverBorder: "#727272",
    primaryCheckboxControlDisabledBorder: masterColors.appDisabledBorder,
    primaryCheckboxControlDisabledBg: masterColors.appDisabledBg,
    primaryCheckboxControlDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryCheckboxControlDisabledHoverBg: masterColors.appDisabledBg,
    primaryCheckboxControlCheckedBg: masterColors.appPrimary,
    primaryCheckboxControlCheckedHoverBg: "#727272",
    primaryCheckboxControlCheckedDisabledBg: masterColors.appDisabledBg,
    primaryCheckboxControlCheckedDisabledStroke: masterColors.appDisabledText,
    primaryCheckboxControlCheckedDisabledHoverBg: masterColors.appDisabledBg,
    primaryCheckboxControlCheckedDisabledHoverStroke:
        masterColors.appDisabledText,
    primaryCheckboxControlIndeterminateBg: masterColors.appPrimary,
    primaryCheckboxControlIndeterminateHoverBg: "#727272",
    primaryCheckboxControlIndeterminateDisabledBg: masterColors.appDisabledBg,
    primaryCheckboxControlIndeterminateDisabledHoverBg:
        masterColors.appDisabledBg,
    primaryCheckboxLabelText: masterColors.appPrimary,
    primaryCheckboxLabelDisabledText: masterColors.appDisabledText,
    primaryCheckboxLabelDisabledHoverText: masterColors.appDisabledText,
    primaryCheckboxIcon: masterColors.appSecondary,

    // RADIO

    primaryRadioControlBorder: masterColors.appBorder,
    primaryRadioControlBg: masterColors.appSecondary,
    primaryRadioControlHoverBorder: "#727272",
    primaryRadioControlCheckedBg: masterColors.appSecondary,
    primaryRadioControlCheckedBorder: masterColors.appPrimary,
    primaryRadioControlCheckedText: masterColors.appPrimary,
    primaryRadioControlCheckedHoverBg: "#F2F2F2",
    primaryRadioControlCheckedHoverBorder: "#727272",
    primaryRadioControlCheckedHoverText: "#727272",
    primaryRadioControlCheckedDisabledBg: masterColors.appDisabledBg,
    primaryRadioControlCheckedDisabledBorder: masterColors.appDisabledBorder,
    primaryRadioControlCheckedDisabledText: "#E8E8E8",
    primaryRadioControlCheckedDisabledHoverBg: masterColors.appDisabledBg,
    primaryRadioControlCheckedDisabledHoverBorder:
        masterColors.appDisabledBorder,
    primaryRadioControlCheckedDisabledHoverText: "#E8E8E8",
    primaryRadioControlDisabledBg: masterColors.appDisabledBg,
    primaryRadioControlDisabledBorder: masterColors.appDisabledBorder,
    primaryRadioControlDisabledHoverBg: masterColors.appDisabledBg,
    primaryRadioControlDisabledHoverBorder: masterColors.appDisabledBorder,
    primaryRadioLabelText: masterColors.appPrimary,
    primaryRadioLabelDisabledText: masterColors.appDisabledText,
    primaryRadioLabelDisabledHoverText: masterColors.appDisabledText,

    // FORM LABEL

    formLabelText: masterColors.appPrimary,
};

export default colors;
