import {
    default as Axios,
    AxiosError,
    AxiosRequestConfig,
    Canceler,
} from "axios";
import { BaseApi } from "./BaseApi";
import {
    FinalResponse,
    ListResponse,
    NotFoundErrorResponse,
    ServerError,
} from "./models";
import {
    transformAddEditErrorResponse,
    transformGetCollectionResponse,
    transformUpdateRequest,
} from "./transformers";
import { PrimitiveObject, SimpleObject } from "./types";

export abstract class EntityApi extends BaseApi {
    protected static GET_COLLECTION = "/";

    protected static POST_COLLECTION = "/";

    protected static GET_ITEM = "/";

    protected static DELETE_ITEM = "/";

    protected static PUT_ITEM = "/";

    protected static PATCH_ITEM = "/";

    public static async getCollection<R>(
        page = 1,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<ListResponse<R> | null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request<R, R>(
            "GET",
            PATH || this.GET_COLLECTION,
            undefined,
            {
                ...extraParams,
                page,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleCollectionResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    public static async postCollection<R, P>(
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request<R, P>("POST", PATH || this.POST_COLLECTION, entity)
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    public static async getItem<R>(
        id: number | string,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request<R, R>(
            "GET",
            this.routeToPath(PATH || this.GET_ITEM, { id }),
            { ...extraParams }
        )
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    public static async putItem<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request<R, P>(
            "PUT",
            this.routeToPath(PATH || this.PUT_ITEM, { id }),
            entity
        )
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    public static async patchItem<R, P>(
        id: number | string,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        const config: AxiosRequestConfig = this.getPatchRequestConfig<P>();

        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request<R, P>(
            "PATCH",
            this.routeToPath(PATH || this.PATCH_ITEM, { id }),
            JSON.stringify(entity),
            {},
            config
        )
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    public static async deleteItem(
        id: number | string,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<null>> {
        const PATH = extraParams?.PATH as string;
        delete extraParams?.PATH;

        return this.request(
            "DELETE",
            this.routeToPath(PATH || this.DELETE_ITEM, { id })
        )
            .then(() => this.handleItemResponse(null))
            .catch((error) => this.handleError(error));
    }

    public static async createOrUpdate<R, P>(
        id: number | string = 0,
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        if (id) {
            return this.patchItem(id, entity, extraParams);
        }
        return this.postCollection(entity, extraParams);
    }

    protected static getPatchRequestConfig<P = null>(): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            transformRequest: [
                (payload: P, headers: SimpleObject<string>) =>
                    transformUpdateRequest(payload, headers),
            ],
        };

        return config;
    }

    public static async getRequest<R>(
        PATH: string,
        extraParams: PrimitiveObject = {},
        cancelToken?: (c: Canceler) => void
    ): Promise<FinalResponse<R> | FinalResponse<null>> {
        const source = this.createCancelTokenSource();

        if (cancelToken) {
            cancelToken(source.cancel);
        }

        return this.request<R, R>(
            "GET",
            PATH,
            undefined,
            {
                ...extraParams,
            },
            {
                cancelToken: source.token,
            }
        )
            .then(({ data }) => this.handleItemResponse<R>(data))
            .catch((error) => this.handleError(error));
    }

    protected static handleItemResponse<R>(data: R): Promise<FinalResponse<R>> {
        return Promise.resolve(new FinalResponse<R>(data));
    }

    protected static handleCollectionResponse<R>(
        data: R
    ): Promise<FinalResponse<ListResponse<R>>> {
        return Promise.resolve(
            new FinalResponse<ListResponse<R>>(
                transformGetCollectionResponse(data as R[])
            )
        );
    }

    protected static handleError(
        error: AxiosError | ServerError
    ): Promise<FinalResponse<null>> {
        const { message } = error;
        if (error instanceof ServerError) {
            return Promise.resolve(new FinalResponse(null, message));
        }

        if (Axios.isCancel(error)) {
            return Promise.resolve(new FinalResponse(null, "Request Cancel"));
        }

        const { response } = error as AxiosError;
        if (response) {
            const { status, data } = response;
            if (status === 422) {
                return Promise.resolve(
                    new FinalResponse(
                        null,
                        transformAddEditErrorResponse(
                            data as string | SimpleObject<any>
                        )
                    )
                );
            } else if (status === 404) {
                return Promise.resolve(
                    new FinalResponse(null, new NotFoundErrorResponse())
                );
            } else if (status === 401) {
                console.log("ToDo: Login form error handling");
            }
        }

        return Promise.resolve(new FinalResponse(null, message));
    }

    public static routeToPath = (
        route: string,
        routeParams: PrimitiveObject
    ) => {
        if (!routeParams) {
            return route;
        }
        Object.keys(routeParams).forEach((param) => {
            route = route.replace(`{${param}}`, `${routeParams[param]}`);
        });
        return route;
    };
}
