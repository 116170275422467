import { BaseEntity } from ".";

export class Speciality extends BaseEntity {
    public name: string = "";

    constructor(model: Partial<Speciality> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PSpeciality = Partial<Speciality>;
