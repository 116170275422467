import { useTrans } from "../../hooks";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { AppFormContainerProps } from "./AppFormContainer";

export const AppModalForm = ({
    title,
    state,
    size,
    children,
}: AppFormContainerProps) => {
    const { t } = useTrans();
    return (
        <Modal
            size={size}
            isOpen={state.isOpen}
            onClose={() => state.close()}
            scrollBehavior={"inside"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>
                <ModalFooter>
                    <Button
                        variant="outline"
                        mr={2}
                        onClick={() => state.close()}
                    >
                        {t("cmn:button.cancel")}
                    </Button>
                    <Button type="submit" form="app-form">
                        {t("cmn:button.save")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
