import { Select } from "@chakra-ui/react";
import { updatePageSize } from "ka-table/actionCreators";
import { IPagingProps } from "ka-table/props";

export const KaPageSizeSelector = ({
    pageSize,
    pageSizes,
    dispatch,
}: IPagingProps) => (
    <>
        <Select
            id="pagination"
            size={"sm"}
            w={"4.375rem"}
            value={pageSize}
            onChange={(event) => {
                dispatch(updatePageSize(Number(event.currentTarget.value)));
            }}
        >
            {pageSizes?.map((value) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </Select>
    </>
);
