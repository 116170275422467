import { useEffect, useRef, useState } from "react";
import { Box, Flex, SimpleGrid, useToast } from "@chakra-ui/react";
import { Canceler } from "axios";
import { User, UserApi } from "../../../apis";

import {
    AppSwitchListview,
    AppMemberFilter,
    AppPagination,
    AppUserRowCard,
    AppUserWidgetCard,
    AppLoader,
} from "../../../components";
import { LSC } from "../../../config";

export const HomePage = () => {
    // hook
    const toast = useToast();
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);

    // state & const
    const [viewType, setViewType] = useState(
        localStorage.getItem(LSC.APP_USER_VIEW_KEY) || "widget"
    );
    const [search, setSearch] = useState("");
    const [localSearch, setLocalSearch] = useState("");
    const [localSearchCategory, setlocalSearchCategory] = useState("");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<User[]>([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(0);
    const [categoryId, setCategoryId] = useState("");
    const pageSize = 12;

    const handleSearch = (value: string, cid: string) => {
        setSearch(value);
        setCategoryId(cid);
    };

    const handleSwitchView = (value: string) => {
        localStorage.setItem(LSC.APP_USER_VIEW_KEY, value);
        setViewType(value);
    };

    const fetchData = () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        params[`order[firstName]`] = "asc";

        if (search) {
            params["user_search"] = search;
        }

        if (categoryId) {
            params["category.id"] = categoryId;
        }

        if (localSearchCategory !== categoryId) {
            setlocalSearchCategory(categoryId);
            if (page != 1) {
                setPage(1);
                return;
            }
        }

        if (localSearch !== search) {
            setLocalSearch(search);
            if (page != 1) {
                setPage(1);
                return;
            }
        }

        UserApi.getUsers<User>(page, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setData(response.items);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [page, search, categoryId]);

    return (
        <>
            <Flex mb={6} flexWrap={"wrap"} gap={3} justifyContent={"flex-end"}>
                <Flex flexGrow={1}>
                    <AppMemberFilter onSearch={handleSearch} />
                </Flex>

                <AppSwitchListview
                    handleSwitchView={handleSwitchView}
                    viewType={viewType}
                />
            </Flex>
            {loading ? (
                <AppLoader />
            ) : (
                <>
                    {viewType === "widget" && (
                        <SimpleGrid
                            columns={{ sm: 1, md: 2, lg: 3 }}
                            spacing={6}
                            overflowX="auto"
                        >
                            {data?.map((user, index) => (
                                <AppUserWidgetCard key={index} user={user} />
                            ))}
                        </SimpleGrid>
                    )}
                    {viewType === "list" && (
                        <Box overflowX="auto">
                            <Flex direction="column" gap={6} p={1}>
                                {data?.map((user, index) => (
                                    <AppUserRowCard key={index} user={user} />
                                ))}
                            </Flex>
                        </Box>
                    )}
                </>
            )}
            {pagesCount > 1 && (
                <Box
                    mt={6}
                    boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
                >
                    <AppPagination
                        onClick={(pageNumber) => setPage(pageNumber)}
                        totalPages={pagesCount}
                        activePage={page}
                    />
                </Box>
            )}
        </>
    );
};
