import { BaseEntity } from ".";

export class Category extends BaseEntity {
    public name: string = "";

    constructor(model: Partial<Category> = {}) {
        super(model?.id);
        Object.assign(this, model);
    }
}

export type PCategory = Partial<Category>;
