import { Global } from "@emotion/react";
import interRegularUrl from "./Inter/Inter-Regular.woff2";
import interMediumUrl from "./Inter/Inter-Medium.woff2";
import interSemiBoldUrl from "./Inter/Inter-SemiBold.woff2";
import interBoldUrl from "./Inter/Inter-Bold.woff2";

const Fonts = () => (
    <Global
        styles={`
      /* latin */
      @font-face {
        font-family: 'Inter Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
				src: local(''), url(${interRegularUrl}) format('woff2');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Medium';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
				src: local(''), url(${interMediumUrl}) format('woff2');
      }
      /* latin */
      @font-face {
        font-family: 'Inter SemiBold';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
				src: local(''), url(${interSemiBoldUrl}) format('woff2');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
				src: local(''), url(${interBoldUrl}) format('woff2');
      }
      `}
    />
);

export default Fonts;
