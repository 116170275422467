import * as yup from "yup";

export const loginPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        username: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email")),
        password: yup.string().required(t("cmn:val.required")),
    });

export const forgotPasswordPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        email: yup
            .string()
            .required(t("cmn:val.required"))
            .email(t("ent.User:email.val.email")),
    });

export const setPasswordPageSchema = (t: (val: string) => string) =>
    yup.object().shape({
        plainPassword: yup.string().required(t("cmn:val.required")),
        plainPasswordConfirm: yup
            .string()
            .required(t("cmn:val.required"))
            .oneOf(
                [yup.ref("plainPassword")],
                t("ent.User:plainPasswordConfirm.val.match")
            ),
    });
