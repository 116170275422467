import tz from "./timezone.json";

export const SITE_NAME = "Mon Rendez-vous CEUSI";
export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 30, 50, 100];
export const FORM_CONTAINER = "drawer";
export const DEFAULT_TIMEZONE = "Europe/Paris";

export const BE = {
    User: {
        ROLE_SUPER_ADMIN: "ROLE_SUPER_ADMIN",
        ROLE_ADMIN: "ROLE_ADMIN",
    },
    Upload: {
        FILETYPE_USER_AVATAR: "USER_AVATAR",
        FILETYPEINFO_USER_AVATAR: {
            key: "USER_AVATAR",
            width: "200",
            height: "200",
            ratio: 1 / 1,
            maxSize: null,
            allowType: ["jpg", "jpeg", "png", "gif", "webp"],
            isSecure: false,
            path: "user_avatar",
        },
    },
    AppointmentBooking: {
        BOOKING_STATUS_CREATED: "CREATED",
        BOOKING_STATUS_ACCEPTED: "ACCEPTED",
        BOOKING_STATUS_CANCELED: "CANCELED",
        BOOKING_STATUS_REJECTED: "REJECTED",
    },
    Timezone: tz,
    SlotDuration: [15, 30, 45, 60, 75, 90, 105, 120],
    Weekdays: {
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday",
        "7": "Sunday",
    },
    ScheduleDays: [7, 15, 30, 60, 90, 120, 150, 180],
    NoticeTime: {
        "60": "1 Hour",
        "120": "2 Hours",
        "180": "3 Hours",
        "240": "4 Hours",
        "360": "6 Hours",
        "720": "12 Hours",
        "1440": "1 Day",
        "2880": "2 Days",
        "4320": "3 Days",
    },
    ReminderTime: {
        "15": "15 Minutes",
        "30": "30 Minutes",
        "60": "1 Hour",
        "120": "2 Hours",
        "180": "3 Hours",
        "240": "4 Hours",
        "360": "6 Hours",
        "720": "12 Hours",
        "1440": "24 Hours",
    },
    Salutation: ["Mr", "Mrs", "Miss", "Dr", "Prof"],
};
