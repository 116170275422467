import { FC, useEffect, useRef, useState } from "react";
import {
    FormContainerStateReturnType,
    useAppTable,
    useResolveUploadedPath,
    useTrans,
} from "../../../hooks";
import { Canceler } from "axios";
import {
    HStack,
    useToast,
    Button,
    Text,
    useDisclosure,
    VStack,
    Badge,
    Avatar,
    Box,
} from "@chakra-ui/react";
import { User as ListEntity, UserApi as ListEntityApi } from "../../../apis";
import { DataType, SortingMode, Table as KaTable } from "ka-table";
import { Column } from "ka-table/models";
import { updatePageIndex } from "ka-table/actionCreators";
import { ICellTextProps } from "ka-table/props";
import { AppAlert, AppIcon, KaPageSizeSelector } from "../../../components";
import { APPC } from "../../../config";

export const UserList: FC<{
    formContainerState: FormContainerStateReturnType;
    search?: string;
}> = ({ formContainerState, search }) => {
    // hook
    const { t } = useTrans();
    const toast = useToast();
    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();

    // state & const
    const [loading, setLoading] = useState(true);
    const [selectId, setSelectId] = useState(0);
    const [list, setList] = useState<ListEntity[]>([]);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    let localSearch: string | undefined;

    // table
    const {
        table,
        pageIndex,
        pagesCount,
        pageSize,
        sortColumn,
        sortOrder,
        setPagesCount,
    } = useAppTable("firstName");

    const columns: Column[] = [
        {
            key: "fullName",
            title: t("ent.User:name.label"),
            dataType: DataType.String,
        },
        {
            key: "email",
            title: t("ent.User:email.label"),
            dataType: DataType.String,
        },
        {
            key: "category?.name",
            title: t("ent.User:category.label"),
            dataType: DataType.String,
        },
        {
            key: "userType",
            title: t("ent.User:userType.label"),
            dataType: DataType.String,
            isSortable: false,
        },
        {
            key: "action",
            title: t("cmn:label.listAction"),
            style: { textAlign: "right" },
            dataType: DataType.Object,
            width: 160,
            isSortable: false,
        },
    ];

    const deleteHandler = async () => {
        if (selectId < 1) {
            return;
        }
        setLoading(true);
        ListEntityApi.deleteItem(selectId)
            .then(({ errorMessage }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else {
                    fetchListData();
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchListData = () => {
        setLoading(true);
        const params = {
            itemsPerPage: pageSize,
        };
        if (localSearch !== search) {
            localSearch = search;
            updatePageIndex(1);
        }

        params[`order[${sortColumn}]`] = sortOrder;
        if (search) {
            params["user_search"] = search;
        }

        ListEntityApi.getCollection<ListEntity>(pageIndex + 1, params, (c) => {
            cancelTokenSourcesRef.current.push(c);
        })
            .then(({ errorMessage, response }) => {
                if (errorMessage) {
                    toast({
                        title: errorMessage,
                        status: "error",
                    });
                } else if (response !== null) {
                    setPagesCount(Math.ceil(response.totalItems / pageSize));
                    setList(response.items);
                }
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        if (!formContainerState.isOpen) {
            fetchListData();
        }
    }, [
        pageIndex,
        pageSize,
        sortColumn,
        sortOrder,
        search,
        formContainerState.isOpen,
    ]);

    const renderEditAction = (props) => (
        <Button
            variant={"outline"}
            size={"sm"}
            className="btn-icon-sm"
            onClick={() => formContainerState.open(props?.rowData?.id)}
        >
            <AppIcon name="icl-pencil" w="1rem" />
        </Button>
    );

    const renderDeleteAction = (props) => {
        const isDisabled =
            props?.rowData?.userType === APPC.BE.User.ROLE_SUPER_ADMIN;
        return (
            <Button
                variant={"destructive"}
                size={"sm"}
                className="btn-icon-sm"
                onClick={() => {
                    setSelectId(props?.rowData?.id);
                    onOpenDelete();
                }}
                isDisabled={isDisabled}
            >
                <AppIcon name="icl-trash" w="1rem" />
            </Button>
        );
    };

    return (
        <>
            <KaTable
                table={table}
                columns={columns}
                data={list || []}
                loading={{
                    enabled: loading,
                }}
                paging={{
                    enabled: true,
                    pageSizes: APPC.PAGE_SIZES,
                    pageSize,
                    pageIndex,
                    pagesCount,
                }}
                rowKeyField={"id"}
                sortingMode={SortingMode.SingleRemote}
                childComponents={{
                    pagingSizes: {
                        content: (props) => <KaPageSizeSelector {...props} />,
                    },
                    cellText: {
                        content: (props: ICellTextProps) => {
                            switch (props?.column?.key) {
                                case "fullName":
                                    return (
                                        <Box
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <Avatar
                                                name={
                                                    props?.rowData?.fullNameNs
                                                }
                                                src={useResolveUploadedPath(
                                                    APPC.BE.Upload
                                                        .FILETYPE_USER_AVATAR,
                                                    props?.rowData?.avatar
                                                )}
                                                size={"sm"}
                                            ></Avatar>
                                            <Text
                                                display={"inline-block"}
                                                textStyle={"mediumSm"}
                                                color={"black"}
                                                ml={2}
                                            >
                                                {props?.rowData?.fullName}
                                            </Text>
                                        </Box>
                                    );
                                case "category?.name":
                                    return props?.rowData?.category
                                        ? `${props?.rowData?.category?.name}`
                                        : "";
                                case "userType":
                                    return (
                                        <Badge variant={"secondary"}>
                                            {t(
                                                `con.User:${props?.rowData?.userType}`
                                            )}
                                        </Badge>
                                    );
                                case "action":
                                    return (
                                        <HStack justify={"flex-end"} gap={3}>
                                            {renderEditAction(props)}
                                            {renderDeleteAction(props)}
                                        </HStack>
                                    );
                            }
                        },
                    },
                }}
            />

            {isOpenDelete && selectId && (
                <AppAlert
                    title={t("cmn:alert.delete.title")}
                    isOpen={isOpenDelete}
                    onSuccess={deleteHandler}
                    onCancel={onCloseDelete}
                    successLable={t("cmn:alert.delete.button.success")}
                    cancelLable={t("cmn:alert.delete.button.cancel")}
                >
                    <VStack py={6}>
                        <AppIcon name="icl-trash" w="2.5rem" color="red" />
                        <Text textStyle={"boldMd"}>
                            {t("cmn:alert.delete.message")}
                        </Text>
                    </VStack>
                </AppAlert>
            )}
        </>
    );
};
