import { ACCEPTABLE_RESPONSE } from "../config";
import { ListResponse, UnprocessableErrorResponse } from "../models";
import { SimpleObject } from "../types";

export const transformAddEditErrorResponse = (
    data: string | SimpleObject<any>
): UnprocessableErrorResponse => {
    const isHydra = ACCEPTABLE_RESPONSE === "HYDRA";

    const errorResponse = new UnprocessableErrorResponse();
    const parsedData: SimpleObject<any> = checkAndParseResponse(data);

    const titleKey = isHydra ? "hydra:title" : "title";
    const descriptionKey = isHydra ? "hydra:description" : "detail";

    if (parsedData[titleKey]) {
        errorResponse.title = parsedData[titleKey];
    }

    if (parsedData[descriptionKey]) {
        errorResponse.description = parsedData[descriptionKey];
    }

    if (parsedData.violations) {
        errorResponse.setViolations(parsedData.violations);
    }

    return errorResponse;
};

export const transformGetCollectionResponse = <T>(
    data: T[]
): ListResponse<T> => {
    const isHydra = ACCEPTABLE_RESPONSE === "HYDRA";

    const listResponse = new ListResponse<T>(data);
    if (!isHydra) {
        return listResponse;
    }
    const parsedData: SimpleObject<any> = checkAndParseResponse(data);

    if (parsedData["hydra:member"]) {
        listResponse.items = parsedData["hydra:member"];
    }
    if (parsedData["hydra:totalItems"]) {
        listResponse.totalItems = parsedData["hydra:totalItems"];
    }
    return listResponse;
};

const checkAndParseResponse = (
    data: string | SimpleObject<any>
): SimpleObject<any> => {
    let parseData: SimpleObject<any> = {};
    if (typeof data === "string") {
        try {
            parseData = JSON.parse(data);
        } catch (e) {
            throw new Error("Invalid response");
        }
    } else {
        parseData = data;
    }

    return parseData;
};
