const clientRoutePath = {
    BASE_PATH: "/",
    ADMIN: {
        BASE_PATH: "admin",
        DASHBOARD_PAGE: "/dashboard",
        PROFILE_PAGE: "/profile",
        USER_PAGE: "/user",
        USER_PAGE_EDIT: "/user/:id",
        CATEGORY_PAGE: "/category",
        CATEGORY_PAGE_EDIT: "/category/:id",
        SPECIALITY_PAGE: "/speciality",
        SPECIALITY_PAGE_EDIT: "/speciality/:id",
        APPOINTMENT_BOOKING_PAGE: "/appointment-booking",
        APPOINTMENT_SETUP_PAGE: "/appointment-setup",
        LOGOUT: "/logout",
    },
    PUBLIC: {
        BASE_PATH: "",
        HOME_PAGE: "",
        DETAIL_PAGE: "memeber/:id",
        SECURITY: {
            LOGIN_PAGE: "login",
            FORGOT_PASSWORD_PAGE: "forgot-password",
            SET_PASSWORD_PAGE: "set-password/:code",
        },
    },
};

export const cPath = (
    key: string,
    params?: { [key: string]: string | number },
    isFixed: boolean = false
): string => {
    const keyChunks = key.split(".");
    let tv = clientRoutePath;
    let path = clientRoutePath["BASE_PATH"];
    keyChunks?.map((value) => {
        if (!value) {
            return;
        }
        if (typeof tv[value] === "string") {
            if (isFixed) {
                path = tv[value].startsWith("/")
                    ? tv[value].substr(1)
                    : tv[value];
            } else {
                path += tv[value];
            }
        }
        tv = tv[value];
        if (typeof tv[value] !== "string" && tv["BASE_PATH"] !== undefined) {
            path += tv["BASE_PATH"];
        }
    });
    if (params) {
        Object.keys(params)?.map(
            (value) => (path = path.replace(`/:${value}`, `/${params[value]}`))
        );
    }
    return path;
};
