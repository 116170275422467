import { API_HOST } from "../services/ApiPlatform/config";

export function useResolveUploadedPath(
    fileType: string,
    fileName: string | null = null,
    file?: File | undefined
): string {
    if (file) {
        return URL.createObjectURL(file);
    }
    const path = "user_avatar";

    console.log(fileType);
    let basePath = `${API_HOST}/uploads`;

    if (path) {
        basePath = `${basePath}/${path}`;
    }

    if (fileName) {
        return `${basePath}/${fileName}`;
    }

    return "";
}
