import { AxiosError, AxiosResponse } from "axios";
import { SimpleObject } from "../types";
import { ServerError } from "../models";
import { LSC } from "../../../config";

export const onResponseFulfilled = (response: AxiosResponse): AxiosResponse => {
    return response;
};

export const onResponseRejected = (error: AxiosError): Promise<any> => {
    if (error.response) {
        const { status, data } = error.response;
        const parsedData = checkAndParseResponse(data);
        const message =
            parsedData["message"] ||
            parsedData["hydra:description"] ||
            parsedData["detail"];

        // status code available
        if (status) {
            if (status === 401) {
                if (
                    message === "Invalid JWT Token" ||
                    message === "Expired JWT Token"
                ) {
                    localStorage.removeItem(LSC.APP_TOKEN_KEY);
                    localStorage.removeItem(LSC.APP_USER_KEY);
                    window.location.reload();
                }

                if (message === "Invalid credentials.") {
                    return Promise.reject(error);
                }

                if (message === "JWT Token not found") {
                    // logOut();
                }

                return Promise.reject(new ServerError(message));
            }

            if (status === 403) {
                return Promise.reject(
                    new ServerError("You are not suppose to be here!")
                );
            }

            if (status === 404 || status === 422) {
                return Promise.reject(error);
            }

            if (status >= 500 && status <= 599) {
                return Promise.reject(new ServerError(message));
            }

            if (message) {
                return Promise.reject(new ServerError(message));
            }

            return Promise.reject(error);
        }
    } else if (error.request) {
        console.error("Request Error:", error.request);

        return Promise.reject(error);
    } else {
        console.error("Error:", error.message);

        return Promise.reject(error);
    }

    return Promise.reject(error);
};

const checkAndParseResponse = (data: unknown): SimpleObject<never> => {
    let parseData: SimpleObject<never> = {};
    if (typeof data === "string") {
        try {
            parseData = JSON.parse(data as string);
        } catch (e) {
            throw new Error("Invalid response");
        }
    } else {
        parseData = data as SimpleObject<never>;
    }

    return parseData;
};
