import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import AppNavItem from "../containers/AppNav/AppNavItem";
import { User } from "../apis";
import { NavItem } from "../containers";
import { useResolveUploadedPath } from "../hooks";
import { APPC } from "../config";

interface AppUserNavProps {
    user: User;
    navItems: NavItem[];
}

export const AppUserNav = ({ user, navItems }: AppUserNavProps) => {
    return (
        <HStack gap={1}>
            <Avatar
                w={8}
                h={8}
                name={user?.fullNameNs}
                src={useResolveUploadedPath(
                    APPC.BE.Upload.FILETYPE_USER_AVATAR,
                    user?.avatar
                )}
            />
            <Popover placement="bottom-end">
                <PopoverTrigger>
                    <Button
                        size={"sm"}
                        className={"btn-icon-header"}
                        variant={"transparent"}
                    >
                        <AppIcon
                            name={"icl-chevron-bottom-alt"}
                            w="1rem"
                            color="black"
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent bg={"#F9F9F9"} mt={2}>
                    <PopoverBody>
                        <VStack
                            gap={1}
                            py={1}
                            ps={1}
                            alignItems={"stretch"}
                            justify={"start"}
                        >
                            <HStack gap={3} px={4} pb={2}>
                                <Avatar
                                    w={10}
                                    h={10}
                                    name={user?.fullNameNs}
                                    src={useResolveUploadedPath(
                                        APPC.BE.Upload.FILETYPE_USER_AVATAR,
                                        user?.avatar
                                    )}
                                />
                                <Box>
                                    <Text textStyle={"heading5"}>
                                        {user?.fullName}
                                    </Text>
                                    <Text textStyle={"regularSm"}>
                                        {user.email}
                                    </Text>
                                </Box>
                            </HStack>
                            <Divider mb={2} />

                            {navItems?.map((item, index) => (
                                <AppNavItem
                                    key={index}
                                    navItem={item}
                                    variant={"transparent"}
                                />
                            ))}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </HStack>
    );
};
