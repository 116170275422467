import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTrans } from "../../../hooks";
import {
    AppStatasticCard,
    AppPageHeader,
    AppStatasticListCard,
    AppLoader,
} from "../../../components";
import { Box, useToast } from "@chakra-ui/react";
import { DashboardApi } from "../../../apis";
import { DashboardMemberResponse } from "../../../apis/entities/dtos";
import { APPC, cPath } from "../../../config";
import { useAuthData } from "../../../contexts";

export const DashboardPage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const navigate = useNavigate();
    const { user } = useAuthData();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<DashboardMemberResponse>();

    useEffect(() => {
        if (user?.userType === APPC.BE.User.ROLE_ADMIN) {
            setLoading(true);
            DashboardApi.dashboardMember<DashboardMemberResponse>()
                .then(({ errorMessage, response }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        setData(response);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <AppLoader />;
    }

    const renderDashboardMember = () => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        padding: "24px 24px 0px 24px",
                        gap: "24px",
                    }}
                >
                    <Box w={"full"}>
                        <AppStatasticCard
                            text1={t("com.AppStatasticCard:text.upcoming")}
                            text2={`${data?.upcomingBooking} ${t(
                                "com.AppStatasticCard:text.bookings"
                            )}`}
                            iconName="icl-calendar-today"
                        />
                    </Box>
                    <Box w={"full"}>
                        <AppStatasticCard
                            text1={t(
                                "com.AppStatasticCard:text.pendingApproval"
                            )}
                            text2={`${data?.pendingApprovalBooking} ${t(
                                "com.AppStatasticCard:text.bookings"
                            )}`}
                            iconName="icl-calendar-today"
                        />
                    </Box>
                    <Box w={"full"}>
                        <AppStatasticCard
                            text1={t("com.AppStatasticCard:text.past")}
                            text2={`${data?.pastBooking} ${t(
                                "com.AppStatasticCard:text.bookings"
                            )}`}
                            iconName="icl-calendar-today"
                        />
                    </Box>
                    <Box w={"full"}>
                        <AppStatasticCard
                            text1={t("com.AppStatasticCard:text.total")}
                            text2={`${data?.totalBooking} ${t(
                                "com.AppStatasticCard:text.bookings"
                            )}`}
                            iconName="icl-calendar-today"
                        />
                    </Box>
                </div>
                {data?.upcomingBookingResult && (
                    <div
                        style={{
                            display: "flex",
                            padding: "24px 24px 0px 24px",
                            gap: "24px",
                        }}
                    >
                        <Box w={"full"}>
                            <AppStatasticListCard
                                title={t(
                                    "com.AppStatasticListCard:title.label"
                                )}
                                data={data?.upcomingBookingResult}
                                onClick={() =>
                                    navigate(
                                        cPath("ADMIN.APPOINTMENT_BOOKING_PAGE")
                                    )
                                }
                            />
                        </Box>
                        <Box w={"full"}></Box>
                        <Box w={"full"}></Box>
                        <Box w={"full"}></Box>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.DashboardPage:text.dashboard")}
            ></AppPageHeader>
            {user?.userType === APPC.BE.User.ROLE_ADMIN &&
                renderDashboardMember()}
        </>
    );
};
