import { Flex } from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";

interface AppSwitchListviewProps {
    handleSwitchView: (value: string) => void;
    viewType: string;
}

export const AppSwitchListview = ({
    handleSwitchView,
    viewType,
}: AppSwitchListviewProps) => {
    return (
        <>
            <Flex
                h={"66px"}
                bg={"#ebebeb"}
                border={"1px solid #DDDDDD"}
                borderRadius={"full"}
                justifyContent={"space-between"}
                alignItems={"center"}
                boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
            >
                <Flex
                    w={"70px"}
                    h={"full"}
                    bg={viewType === "widget" ? "#FFFFFF" : ""}
                    border={viewType === "widget" ? "1px solid #dddddd" : ""}
                    borderRadius={"full"}
                    _hover={viewType !== "widget" ? { bg: "#dddddd" } : {}}
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={() => handleSwitchView("widget")}
                >
                    <AppIcon name={"icl-view-module"} />
                </Flex>
                <Flex
                    w={"70px"}
                    h={"full"}
                    bg={viewType === "list" ? "#FFFFFF" : ""}
                    border={viewType === "list" ? "1px solid #dddddd" : ""}
                    borderRadius={"full"}
                    _hover={viewType !== "list" ? { bg: "#dddddd" } : {}}
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    onClick={() => handleSwitchView("list")}
                >
                    <AppIcon name={"icl-view-agenda"} />
                </Flex>
            </Flex>
        </>
    );
};
