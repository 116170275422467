import { default as Axios, AxiosInstance } from "axios";
import {
    API_HOST,
    ACCEPTABLE_RESPONSE,
    REQUEST_ACCEPT_HEADERS,
} from "./config";
import {
    onRequestFulfilled,
    onRequestRejected,
    onResponseFulfilled,
    onResponseRejected,
} from "./interceptors";
import QueryString from "qs";

// Axios.defaults = Object.assign(Axios.defaults, defaultConfig);
export const axiosInstance: AxiosInstance = Axios.create({
    baseURL: API_HOST,
    timeout: 1000 * 60,
    headers: {
        Accept: REQUEST_ACCEPT_HEADERS[ACCEPTABLE_RESPONSE],
    },
    paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: "brackets" }),
});

axiosInstance.interceptors.request.use(onRequestFulfilled, onRequestRejected);

axiosInstance.interceptors.response.use(
    onResponseFulfilled,
    onResponseRejected
);
