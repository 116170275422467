import dayjs from "dayjs";
import { APPC } from "../config";

type useAppUtilType = {
    getTimeOptions: (slotDuration: number) => string[];
    getFileFromBlob: (blob: Blob, file: File) => File;
    statusVariant: (status: string) => string;
};

export function useAppUtil(): useAppUtilType {
    const getTimeOptions = (slotDuration: number): string[] => {
        const slots: string[] = [];
        let t = dayjs().hour(0).minute(0).second(0);
        for (let i = 0; i <= 1440 / slotDuration; i++) {
            slots.push(t.format("HH:mm"));
            t = t.add(slotDuration, "minute");
        }
        return slots;
    };

    const getFileFromBlob = (blob: Blob, file: File) =>
        new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
        });

    const statusVariant = (status: string) => {
        switch (status) {
            case APPC.BE.AppointmentBooking.BOOKING_STATUS_CREATED:
                return "secondary";
            case APPC.BE.AppointmentBooking.BOOKING_STATUS_ACCEPTED:
                return "green";
            case APPC.BE.AppointmentBooking.BOOKING_STATUS_REJECTED ||
                APPC.BE.AppointmentBooking.BOOKING_STATUS_CANCELED:
                return "red";
            default:
                return "";
        }
    };

    return {
        getTimeOptions,
        getFileFromBlob,
        statusVariant,
    };
}
