import { useNavItems } from "../hooks";
import { useAuthData } from "../contexts";
import { HStack } from "@chakra-ui/react";
import { AppUserNav } from "./AppUserNav";

export const AppHeader = () => {
    const { user } = useAuthData();
    const userNavItems = useNavItems("user");

    return (
        <>
            <HStack h={"full"} px={6} justifyContent={"flex-end"} gap={8}>
                {user && <AppUserNav user={user} navItems={userNavItems} />}
            </HStack>
        </>
    );
};
