import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContainerState, useTrans } from "../../../hooks";
import { VStack, HStack } from "@chakra-ui/react";
import { AppPageHeader } from "../../../components";
import { AppointmentBookingList as ListComponent } from "./AppointmentBookingList";
import { AppointmentBookingDetailView as DetailView } from "./AppointmentBookingDetailView";
import { AppSearch } from "../../../containers";

export const AppointmentBookingPage = () => {
    // hook
    const { t } = useTrans();
    const { id } = useParams();
    const formContainerState = useFormContainerState();

    const [search, setSearch] = useState("");

    useEffect(() => {
        if (id) {
            formContainerState.open(id);
        }
    }, [id]);

    return (
        <>
            <AppPageHeader
                title={t("padm.AppointmentBookingPage:text.booking")}
            >
                <HStack gap={4} py={{ base: 3.5, sm: 4, md: "1.125rem" }}>
                    <AppSearch onSearch={(value) => setSearch(value)} />
                </HStack>
            </AppPageHeader>
            <VStack p={6}>
                <ListComponent
                    formContainerState={formContainerState}
                    search={search}
                />
            </VStack>
            {formContainerState.isOpen && (
                <DetailView formContainerState={formContainerState} />
            )}
        </>
    );
};
