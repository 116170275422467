import { useTrans } from "../../hooks";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";
import { AppFormContainerProps } from "./AppFormContainer";

export const AppDrawerForm = ({
    title,
    state,
    size,
    children,
}: AppFormContainerProps) => {
    const { t } = useTrans();
    return (
        <Drawer
            size={size}
            isOpen={state.isOpen}
            placement="right"
            onClose={() => state.close()}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{title}</DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
                <DrawerFooter>
                    <Button
                        variant="outline"
                        mr={2}
                        onClick={() => state.close()}
                    >
                        {t("cmn:button.cancel")}
                    </Button>
                    <Button type="submit" form="app-form">
                        {t("cmn:button.save")}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};
