import { EntityApi } from "../../services/ApiPlatform";
import { FinalResponse } from "../../services/ApiPlatform/models";
import { PrimitiveObject } from "../../services/ApiPlatform/types";

export abstract class AuthApi extends EntityApi {
    protected static API_LOGIN_CHECK = "/login_check";
    protected static API_ME = "/api/me";

    public static async loginCheck<R, P>(
        entity: P,
        extraParams: PrimitiveObject = {}
    ): Promise<FinalResponse<R | null>> {
        extraParams = { ...extraParams, PATH: this.API_LOGIN_CHECK };
        return this.postCollection(entity, extraParams);
    }

    public static async me<R>(): Promise<FinalResponse<R | null>> {
        return this.getRequest(this.API_ME);
    }
}
