import { useRef, useState, Suspense, lazy } from "react";
import { CropperRef } from "react-advanced-cropper";
import {
    useAppUtil,
    useAuthAction,
    useResolveUploadedPath,
    useTrans,
} from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { useAuthData } from "../../../contexts";
import {
    AppAlert,
    AppFormControl,
    AppIcon,
    AppLoader,
    AppPageHeader,
} from "../../../components";
import {
    User as FormEntity,
    UserApi as FormEntityApi,
    UploadApi,
} from "../../../apis";
import {
    Avatar,
    Box,
    Button,
    Divider,
    HStack,
    Input,
    Select,
    Stack,
    Text,
    Textarea,
    VStack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { APPC } from "../../../config";
import {
    ForgotPasswordPayload,
    ForgotPasswordResponse,
} from "../../../apis/entities/dtos";

const Cropper = lazy(() =>
    import("react-advanced-cropper").then((module) => ({
        default: module.Cropper,
    }))
);

export const ProfilePage = () => {
    const { t } = useTrans();
    const toast = useToast();
    const { user } = useAuthData();
    const [loading, setLoading] = useState(false);
    const { reloadUserAction, logoutAction } = useAuthAction();
    const { getFileFromBlob } = useAppUtil();

    const {
        isOpen: isOpenChangePwdAlert,
        onOpen: onOpenChangePwdAlert,
        onClose: onCloseChangePwdAlert,
    } = useDisclosure();

    const [uploadedFile, setUploadedFile] = useState<File>();
    const [croppedFile, setCroppedFile] = useState<File | undefined>(undefined);

    // cropper
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<CropperRef>(null);
    const onCropperDone = async () => {
        if (cropperRef?.current && uploadedFile) {
            await cropperRef?.current?.getCanvas()?.toBlob(
                async (blob) => {
                    if (blob) {
                        setCroppedFile(getFileFromBlob(blob, uploadedFile));
                    }
                },
                uploadedFile.type,
                1
            );
            setShowCropper(false);
        }
    };
    const onRotate = async (isCcw = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.rotateImage(isCcw ? 90 : -90);
        }
    };
    const onZoom = async (isZoomOut = false) => {
        if (cropperRef?.current && uploadedFile) {
            cropperRef?.current?.zoomImage(isZoomOut ? 0.5 : 2);
        }
    };

    // form
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm<FormEntity>({
        resolver: yupResolver(schema(t)),
        mode: "onSubmit",
    });

    const onSubmitHandler = async (formData: FormEntity) => {
        if (user) {
            setLoading(true);
            if (croppedFile) {
                const uploadFormData = new FormData();
                uploadFormData.set(
                    "fileType",
                    APPC.BE.Upload.FILETYPE_USER_AVATAR
                );
                uploadFormData.set("file", croppedFile);

                await UploadApi.upload<{ fileName: string }, FormData>(
                    uploadFormData
                ).then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        formData.avatar = response.fileName;
                    }
                });
            }
            FormEntityApi.patchItemProfile<FormEntity, FormEntity>(
                user.id,
                formData
            )
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        reloadUserAction();
                        toast({
                            title: t("cmn:message.save.success"),
                            status: "success",
                        });
                        reset();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const changePasswordHandler = async () => {
        if (user) {
            setLoading(true);
            const payload = {
                email: user.email,
            };
            FormEntityApi.postUserChangePasswordRequest<
                ForgotPasswordResponse,
                ForgotPasswordPayload
            >(payload)
                .then(({ response, errorMessage }) => {
                    if (errorMessage) {
                        toast({
                            title: errorMessage,
                            status: "error",
                        });
                    } else if (response !== null) {
                        toast({
                            title: t(
                                "padm.ProfilePage:toast.success.changePassword"
                            ),
                            status: "success",
                        });
                        reset();
                        logoutAction();
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const renderUserForm = () => {
        return (
            <form
                id="app-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmitHandler)();
                }}
            >
                {loading && <AppLoader />}
                <VStack gap={6} maxW={"600px"}>
                    <Box position="relative" w="100%">
                        <Text textStyle={"heading5"}>
                            {t("padm.ProfilePage:text.personalInfo")}
                        </Text>
                        <Divider />
                    </Box>
                    <AppFormControl>
                        <Box textAlign={"center"}>
                            <Avatar
                                size={"xl"}
                                name={user?.fullNameNs}
                                src={useResolveUploadedPath(
                                    APPC.BE.Upload.FILETYPE_USER_AVATAR,
                                    user?.avatar,
                                    croppedFile
                                )}
                            >
                                <Box
                                    display={"flex"}
                                    pos={"absolute"}
                                    bottom={2}
                                    right={2}
                                    bg="#fff"
                                    borderRadius={"full"}
                                    padding={1}
                                >
                                    <AppIcon
                                        name="icl-pencil"
                                        color="#000"
                                        w="1rem"
                                    />
                                </Box>
                            </Avatar>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                        setUploadedFile(file);
                                        setShowCropper(true);
                                    }
                                    e.target.value = "";
                                }}
                                opacity={0}
                                position={"absolute"}
                                w={"full"}
                                h={"full"}
                                top={0}
                                left={0}
                            />
                            {showCropper && uploadedFile && (
                                <Box border={"1px solid #000"} p={4} mt={4}>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <Cropper
                                            ref={cropperRef}
                                            src={URL.createObjectURL(
                                                uploadedFile
                                            )}
                                            className={"cropper"}
                                            stencilProps={{
                                                aspectRatio:
                                                    APPC.BE.Upload
                                                        .FILETYPEINFO_USER_AVATAR
                                                        .ratio,
                                            }}
                                        />
                                    </Suspense>
                                    <HStack
                                        justify={"space-between"}
                                        mt={4}
                                        wrap={"wrap"}
                                    >
                                        <HStack spacing={2}>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onZoom()}
                                            >
                                                <AppIcon
                                                    name="icl-zoom-in"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onZoom(true)}
                                            >
                                                <AppIcon
                                                    name="icl-zoom-out"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onRotate()}
                                            >
                                                <AppIcon
                                                    name="icl-action-undo"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => onRotate(true)}
                                            >
                                                <AppIcon
                                                    name="icl-action-redo"
                                                    w="1rem"
                                                />
                                            </Button>
                                        </HStack>
                                        <HStack spacing={2}>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={() => {
                                                    setUploadedFile(undefined);
                                                    setShowCropper(false);
                                                }}
                                            >
                                                <AppIcon
                                                    name="icl-x"
                                                    w="1rem"
                                                />
                                            </Button>
                                            <Button
                                                variant={"primary"}
                                                size={"sm"}
                                                className="btn-icon-sm"
                                                onClick={onCropperDone}
                                            >
                                                <AppIcon
                                                    name="icl-check-alt"
                                                    w="1rem"
                                                />
                                            </Button>
                                        </HStack>
                                    </HStack>
                                </Box>
                            )}
                        </Box>
                    </AppFormControl>
                    <AppFormControl label={t("ent.User:email.label")}>
                        <Input
                            type="email"
                            placeholder={t("ent.User:email.placeholder")}
                            isDisabled={true}
                            defaultValue={user?.email}
                        />
                    </AppFormControl>
                    <Stack
                        gap={4}
                        w={"full"}
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        {/*
                        <Button
                            minWidth={"220px"}
                            w={{ base: "100%", sm: "100%", md: "50%" }}
                            variant={"destructive"}
                        >
                            <AppIcon name="icl-mail" w="1rem" />
                            <Text ml="2">
                                {t("padm.ProfilePage:button.changeEmail")}
                            </Text>
                        </Button>
                        */}
                        <Button
                            minWidth={"220px"}
                            w={{ base: "100%", sm: "100%", md: "50%" }}
                            variant={"destructive"}
                            onClick={onOpenChangePwdAlert}
                        >
                            <AppIcon name="icl-key-alt" />
                            <Text ml="2">
                                {t("padm.ProfilePage:button.changePassword")}
                            </Text>
                        </Button>
                    </Stack>
                    <AppFormControl
                        label={t("ent.User:salutation.label")}
                        isInvalid={!!errors?.salutation}
                        message={errors?.salutation?.message}
                    >
                        <Select
                            placeholder={t("ent.User:salutation.placeholder")}
                            {...register("salutation")}
                            defaultValue={user?.salutation}
                        >
                            {APPC.BE.Salutation?.map((sl) => (
                                <option key={sl} value={sl}>
                                    {sl}
                                </option>
                            ))}
                        </Select>
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:firstName.label")}
                        isInvalid={!!errors?.firstName}
                        message={errors?.firstName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:firstName.placeholder")}
                            {...register("firstName")}
                            defaultValue={user?.firstName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:lastName.label")}
                        isInvalid={!!errors?.lastName}
                        message={errors?.lastName?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:lastName.placeholder")}
                            {...register("lastName")}
                            defaultValue={user?.lastName}
                        />
                    </AppFormControl>
                    <AppFormControl
                        label={t("ent.User:phone.label")}
                        isInvalid={!!errors?.phone}
                        message={errors?.phone?.message}
                    >
                        <Input
                            type="text"
                            placeholder={t("ent.User:phone.placeholder")}
                            {...register("phone")}
                            defaultValue={user?.phone}
                            autoComplete="on"
                        />
                    </AppFormControl>
                    {/*
                    <AppFormControl
                        label={t("ent.User:timezone.label")}
                        isInvalid={!!errors?.timezone}
                        message={errors?.timezone?.message}
                    >
                        <Select
                            placeholder={t("ent.User:timezone.placeholder")}
                            {...register("timezone")}
                            defaultValue={user?.timezone}
                        >
                            {Object.keys(APPC.BE.Timezone)?.map((tz) => (
                                <option key={tz} value={tz}>
                                    {APPC.BE.Timezone[tz]}
                                </option>
                            ))}
                        </Select>
                    </AppFormControl>
                    */}
                    <AppFormControl
                        label={t("ent.User:bioData.label")}
                        isInvalid={!!errors?.bioData}
                        message={errors?.bioData?.message}
                    >
                        <Textarea
                            placeholder={t("ent.User:bioData.placeholder")}
                            {...register("bioData")}
                            defaultValue={user?.bioData}
                        />
                    </AppFormControl>
                    <Box alignSelf={"end"}>
                        <Button type="submit" form="app-form">
                            {t("cmn:button.save")}
                        </Button>
                    </Box>
                </VStack>
            </form>
        );
    };

    return (
        <>
            <AppPageHeader
                title={t("padm.ProfilePage:text.profile")}
            ></AppPageHeader>
            <Box p={6}>{renderUserForm()}</Box>
            {isOpenChangePwdAlert && (
                <AppAlert
                    title={t("padm.ProfilePage:alert.changePassword.title")}
                    isOpen={isOpenChangePwdAlert}
                    onSuccess={changePasswordHandler}
                    onCancel={onCloseChangePwdAlert}
                    successLable={t(
                        "padm.ProfilePage:alert.changePassword.button.success"
                    )}
                    cancelLable={t(
                        "padm.ProfilePage:alert.changePassword.button.cancel"
                    )}
                    size={"xl"}
                >
                    <VStack py={6}>
                        <AppIcon name="icl-warning" w="2.5rem" color="orange" />
                        <Text textStyle={"boldMd"}>
                            {t("padm.ProfilePage:alert.changePassword.message")}
                        </Text>
                    </VStack>
                </AppAlert>
            )}
        </>
    );
};
