import { FC } from "react";

type AppIconProps = {
    name: string;
    w?: string;
    h?: string;
    color?: string;
    customAttribute?: string;
};

export const AppIcon: FC<AppIconProps> = ({
    name,
    w = "1.25rem",
    h,
    color = "currentColor",
}) => {
    return (
        <svg
            className={`ic-icon ${name}`}
            focusable="false"
            width={w}
            height={h || w}
            color={color}
        >
            <use xlinkHref={`#${name}`}></use>
        </svg>
    );
};
