import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

export type ButtonVariants =
    | "primary"
    | "secondary"
    | "outline"
    | "destructive"
    | "transparent";

const baseStyle = defineStyle({
    fontFamily: "Inter Medium, sans-serif",
    fontWeight: 500,
    borderRadius: "0.25rem",
});

const sizes = {
    xs: defineStyle({
        px: 3,
        py: 2,
        fontSize: "0.75rem",
        lineHeight: "1.05rem",
        minW: "unset",
    }),
    sm: defineStyle({
        px: 3,
        py: 2,
        fontSize: "0.875rem",
        lineHeight: "1.3125rem",
        minW: "unset",
    }),
    md: defineStyle({
        px: "1.125rem",
        py: 2,
        fontSize: "1rem",
        lineHeight: 6,
        minW: "unset",
    }),
    lg: defineStyle({
        px: "1.125rem",
        py: 3,
        fontSize: "1.125rem",
        lineHeight: "1.6875rem",
        minW: "unset",
    }),
};

const variantPrimary = defineStyle({
    bg: "primaryButtonBg",
    color: "primaryButtonText",
    _hover: {
        bg: "primaryButtonHoverBg",
        color: "primaryButtonHoverText",
    },
    _active: {
        bg: "primaryButtonActiveBg",
        color: "primaryButtonActiveText",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        bg: "primaryButtonDisabledBg",
        color: "primaryButtonDisabledText",
        _hover: {
            bg: "primaryButtonDisabledHoverBg !important",
            color: "primaryButtonDisabledHoverText",
        },
    },
});

const variantSecondary = defineStyle({
    bg: "secondaryButtonBg",
    color: "secondaryButtonText",
    _hover: {
        bg: "secondaryButtonHoverBg",
        color: "secondaryButtonHoverText",
    },
    _active: {
        bg: "secondaryButtonActiveBg",
        color: "secondaryButtonActiveText",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        bg: "secondaryButtonDisabledBg",
        color: "secondaryButtonDisabledText",
        _hover: {
            bg: "secondaryButtonDisabledHoverBg !important",
            color: "secondaryButtonDisabledHoverText",
        },
    },
});

const variantOutline = defineStyle({
    bg: "outlineButtonBg",
    color: "outlineButtonText",
    border: "1px solid var(--chakra-colors-outlineButtonBorder) !important",
    _hover: {
        bg: "outlineButtonHoverBg",
        color: "outlineButtonHoverText",
        border: "1px solid var(--chakra-colors-outlineButtonHoverBorder)",
    },
    _active: {
        bg: "outlineButtonActiveBg",
        color: "outlineButtonActiveText",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        bg: "outlineButtonDisabledBg",
        color: "outlineButtonDisabledText",
        border: "1px solid var(--chakra-colors-outlineButtonDisabledBorder) !important",
        _hover: {
            bg: "outlineButtonDisabledHoverBg !important",
            color: "outlineButtonDisabledHoverText",
            border: "1px solid var(--chakra-colors-outlineButtonDisabledHoverBorder) !important",
        },
    },
});

const variantDestructive = defineStyle({
    bg: "destructiveButtonBg",
    color: "destructiveButtonText",
    border: "1px solid var(--chakra-colors-destructiveButtonBorder)",
    _hover: {
        bg: "destructiveButtonHoverBg",
        color: "destructiveButtonHoverText",
        border: "1px solid var(--chakra-colors-destructiveButtonHoverBorder)",
    },
    _active: {
        bg: "destructiveButtonActiveBg",
        color: "destructiveButtonActiveText",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        bg: "destructiveButtonDisabledBg",
        color: "destructiveButtonDisabledText",
        border: "1px solid var(--chakra-colors-destructiveButtonDisabledBorder)",
        _hover: {
            bg: "destructiveButtonDisabledHoverBg !important",
            color: "destructiveButtonDisabledHoverText",
            border: "1px solid var(--chakra-colors-destructiveButtonDisabledHoverBorder)",
        },
    },
});

const variantTransparent = defineStyle({
    w: "100%",
    bg: "transparentButtonBg",
    color: "transparentButtonText",
    justifyContent: "start",
    _hover: {
        bg: "transparentButtonHoverBg",
        color: "transparentButtonHoverText",
    },
    _active: {
        bg: "transparentButtonActiveBg",
        color: "transparentButtonActiveText",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        bg: "transparentButtonDisabledBg",
        color: "transparentButtonDisabledText",
        _hover: {
            bg: "transparentButtonDisabledHoverBg !important",
            color: "transparentButtonDisabledHoverText",
        },
    },
});

const variantTab = defineStyle({
    bg: "tabButtonBg",
    color: "tabButtonText",
    _active: {
        color: "tabButtonActiveText",
        borderRadius: 0,
        borderBottom: "3px solid var(--chakra-colors-tabButtonActiveBorder)",
    },
    _focusVisible: {
        boxShadow: "0 0 0 3px rgba(94, 99, 103, 0.6)",
    },
    _disabled: {
        opacity: 1,
        color: "tabButtonDisabledText",
        borderBottom: 0,
        _hover: {
            color: "tabButtonDisabledHoverText",
            borderBottom: 0,
        },
    },
});

const variants = {
    primary: variantPrimary,
    secondary: variantSecondary,
    outline: variantOutline,
    destructive: variantDestructive,
    transparent: variantTransparent,
    tab: variantTab,
};

export const Button = defineStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});
