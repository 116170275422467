import { useNavigate } from "react-router-dom";
import {
    Avatar,
    HStack,
    VStack,
    Text,
    Divider,
    Flex,
    Box,
} from "@chakra-ui/react";
import { AppIcon } from "./AppIcon";
import { User } from "../apis";
import { APPC, cPath } from "../config";
import { useResolveUploadedPath, useTrans } from "../hooks";

interface AppUserCard3 {
    user: User;
}

export const AppUserWidgetCard = ({ user }: AppUserCard3) => {
    const { t } = useTrans();
    const navigate = useNavigate();
    return (
        <Flex
            h={"436px"}
            border={"1px solid #E1E1E1"}
            borderRadius={"10px"}
            bg={"#ffffff"}
            boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
            _hover={{ bg: "#f6f6f6" }}
            cursor={"pointer"}
            onClick={() => {
                navigate(cPath("PUBLIC.DETAIL_PAGE", { id: user.id }, false));
            }}
        >
            <VStack
                justifyContent={"space-between"}
                h={"full"}
                w={"full"}
                gap={0}
            >
                <VStack
                    alignItems={"center"}
                    w={"full"}
                    gap={"24px"}
                    border={"1px solid #ddd"}
                    borderRadius={"10px"}
                    bg={"#ebebeb"}
                    pt={"30px"}
                    pb={"15px"}
                >
                    <Avatar
                        size={"2xl"}
                        name={user?.fullNameNs}
                        src={useResolveUploadedPath(
                            APPC.BE.Upload.FILETYPE_USER_AVATAR,
                            user?.avatar
                        )}
                    />
                    <Box fontSize={"22px"} fontWeight={"semibold"}>
                        {user?.fullName}
                        <Divider
                            mt={"7px"}
                            w={"75px"}
                            borderBottomColor="black"
                        />
                    </Box>
                </VStack>
                <VStack
                    gap={"16px"}
                    alignItems={"flex-start"}
                    display={"flex"}
                    w={"full"}
                    h={"full"}
                    p={"30px"}
                    overflowX={"hidden"}
                >
                    {user?.phone && (
                        <HStack gap={"1rem"} color={"#222222"}>
                            <AppIcon name={"icl-phone"} w="1rem" />
                            <Text fontSize={"14px"}>{user?.phone}</Text>
                        </HStack>
                    )}
                    <HStack gap={"1rem"} color={"#222222"}>
                        <AppIcon name={"icl-mail"} w="1rem" />
                        <Text fontSize={"14px"}>{user.email}</Text>
                    </HStack>
                    {user?.bioData && (
                        <Text
                            fontSize={"13px"}
                            lineHeight={"20px"}
                            color={"#787878"}
                            textAlign={"left"}
                            w={"full"}
                        >
                            {`${user?.bioData}`.substring(0, 120)}
                            <Text
                                as={"span"}
                                cursor={"pointer"}
                                fontWeight={"semibold"}
                            >
                                . . . {t("com.AppUserWidgetCard:text.more")}
                            </Text>
                        </Text>
                    )}
                </VStack>
            </VStack>
        </Flex>
    );
};
