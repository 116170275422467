import {
    Box,
    Center,
    Divider,
    Flex,
    VStack,
    Text,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AppIcon } from "./AppIcon";
import { Category, CategoryApi } from "../apis";
import { useDebounce, useTrans } from "../hooks";

type AppMemberFilterProps = {
    onSearch: (v: string, cid: string) => void;
};

export const AppMemberFilter = ({ onSearch }: AppMemberFilterProps) => {
    const { t } = useTrans();
    const toast = useToast();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isInputActive, setIsInputActive] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isSelectActive, setSelectActive] = useState(false);
    const [value, setValue] = useState("");
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(
        null
    );

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
            setIsInputActive(true);
        }
        setSelectActive(false);
        setIsHovered(true);
    };

    const onMouseLeave = () => {
        if (isInputActive || isSelectActive) {
            setIsHovered(true);
        } else {
            setIsHovered(false);
        }
    };

    const debounceSearch = useDebounce(() => {
        const cat = selectedCategory ? `${selectedCategory?.id}` : "";
        onSearch(value, cat);
    }, 300);

    const handleSearch = (v: string) => {
        setValue(`${v}`);
        debounceSearch();
    };

    const onChangeCategory = (c: Category | null) => {
        setSelectedCategory(c);
        debounceSearch();
    };

    useEffect(() => {
        CategoryApi.getCategories<Category>(1, {
            pagination: false,
        }).then(({ errorMessage, response }) => {
            if (errorMessage) {
                toast({
                    title: errorMessage,
                    status: "error",
                });
            } else if (response !== null) {
                setCategories(response.items);
            }
        });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                setIsInputActive(false);
                setSelectActive(false);
                setIsHovered(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef]);

    return (
        <>
            <Flex
                ref={containerRef}
                flexBasis={"850px"}
                h={"66px"}
                borderRadius={"full"}
                bg={isInputActive || isSelectActive ? "#ebebeb" : ""}
                border={"1px solid #DDDDDD"}
                justifyContent={"space-between"}
                alignItems={"center"}
                boxShadow={`0 3px 12px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.08)`}
            >
                <Menu offset={[30, 4]}>
                    <MenuButton
                        as={Box}
                        flexBasis={"385px"}
                        h={"64px"}
                        px={"35px"}
                        py={"14px"}
                        bg={isSelectActive ? "#FFFFFF" : ""}
                        _hover={
                            isSelectActive
                                ? { bg: "#FFFFFF" }
                                : { bg: "#dddddd" }
                        }
                        borderRadius={"full"}
                        display={"flex"}
                        alignItems={"center"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={onMouseLeave}
                        onClick={() => {
                            setSelectActive(true);
                            setIsInputActive(false);
                            setIsHovered(true);
                        }}
                        cursor={"pointer"}
                    >
                        <HStack gap={4}>
                            <VStack
                                flexGrow={1}
                                alignItems={"flex-start"}
                                gap={0}
                            >
                                <Text fontSize={"12px"} w={"full"}>
                                    {t("com.AppMemberFilter:text.select")}
                                </Text>
                                <Text
                                    fontSize={"14px"}
                                    fontWeight={800}
                                    w={"full"}
                                >
                                    {selectedCategory
                                        ? selectedCategory?.name
                                        : t(
                                              "com.AppMemberFilter:select.placeholder"
                                          )}
                                </Text>
                            </VStack>
                            <Box>
                                <AppIcon
                                    name={"icl-chevron-bottom-alt"}
                                    w="1rem"
                                />
                            </Box>
                        </HStack>
                    </MenuButton>

                    <MenuList w={"270px"}>
                        <MenuItem onClick={() => onChangeCategory(null)}>
                            {t("com.AppMemberFilter:select.optionVal")}
                        </MenuItem>
                        {categories.map((c) => (
                            <MenuItem
                                key={c.id}
                                onClick={() => onChangeCategory(c)}
                            >
                                {c.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                {!isHovered && (
                    <Center height="34px">
                        <Divider
                            orientation="vertical"
                            borderColor={"#dddddd"}
                        />
                    </Center>
                )}
                <Flex
                    flexBasis={"458px"}
                    h={"64px"}
                    px={"35px"}
                    py={"14px"}
                    bg={isInputActive ? "#FFFFFF" : ""}
                    _hover={
                        isInputActive ? { bg: "#FFFFFF" } : { bg: "#dddddd" }
                    }
                    borderRadius={"full"}
                    cursor={"pointer"}
                    onClick={focusInput}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={onMouseLeave}
                >
                    <HStack w={"full"}>
                        <VStack
                            flexGrow={1}
                            alignItems={"flex-start"}
                            h={"full"}
                            gap={0}
                        >
                            <Text fontSize={"12px"}>
                                {t("com.AppMemberFilter:text.search")}
                            </Text>
                            <Text fontSize={"14px"} fontWeight={800}>
                                <input
                                    id="memberSearch"
                                    value={value}
                                    ref={inputRef}
                                    className="prompt-input"
                                    onChange={(e) =>
                                        handleSearch(e.currentTarget.value)
                                    }
                                />
                            </Text>
                        </VStack>
                        <Button
                            display={value ? "block" : "none"}
                            variant={"transparent"}
                            size={"sm"}
                            className="btn-icon-sm"
                            onClick={() => handleSearch("")}
                        >
                            <AppIcon name="icl-x" w="1rem" />
                        </Button>
                    </HStack>
                </Flex>
            </Flex>
        </>
    );
};
