import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
    tab: {
        fontFamily: "Inter Medium, sans-serif",
        fontWeight: 500,
    },
    tabpanel: {
        fontFamily: "Inter Regular, sans-serif",
    },
});

const variantApp = definePartsStyle({
    tab: {
        color: "tabText",
        _selected: {
            borderBottom: "3px solid var(--chakra-colors-tabSelectedBorder)",
            borderRadius: "0",
            color: "tabSelectedText",
        },
    },
});

const variants = {
    app: variantApp,
};

export const Tabs = defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {
        size: "md",
        variant: "app",
    },
});
