import { Center, Spinner } from "@chakra-ui/react";

export const AppLoader = () => {
    return (
        <Center
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="#00000000"
            zIndex="1500"
        >
            <Spinner
                size="xl"
                thickness="4px"
                speed="0.65s"
                color="#000000"
                emptyColor="gray.200"
            />
        </Center>
    );
};
